import { UPDATE_CHAT_GPT } from '../actions/actions';

export const initialState = false;

const chatGPTReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CHAT_GPT: {
      return action.payload
    }
    default: {
      return state;
    }
  }
};

export { chatGPTReducer };
