import { v4 as uuid } from 'uuid';

export const addQuestionChecks = (botType, type, que) => {
    
    switch (type) {
        case 'button': {
            que.options = [
                { type, value: 'Option 1', id: uuid(), next: { type: '', target: '' } },
                { type, value: 'Option 2', id: uuid(), next: { type: '', target: '' } }
            ];
            if (botType?.toLowerCase() === 'whatsapp') {
                que = {
                    ...que,
                    source: '',
                    header: 'Welcome ',
                    footer: "Press '0' to restart and  '1' for live chat",
                    button: "Press",
                    required: true,
                    message: {
                        error: "Please select an option from above given choices!"
                    }
                }
            }
            return que;
        }
        case 'multi_select': {
            que.options = [
                { type, value: 'Option 1', id: uuid(), next: { type: '', target: '' } },
                { type, value: 'Option 2', id: uuid(), next: { type: '', target: '' } }
            ];
            if (botType?.toLowerCase() === 'whatsapp') {
                que = {
                    ...que,
                    source: '',
                    header: 'Welcome ',
                    footer: "Press '0' to restart and  '1' for live chat",
                    button: "Press",
                    required: true
                }
            }
            return que;
        }
        case 'smart_question': {
            que.options = [
                { type, smartKeywords: [], id: uuid(), next: { type: '', target: '' }, keywordType: "CONTAIN" },
                { type, smartKeywords: [], id: uuid(), next: { type: '', target: '' }, keywordType: "CONTAIN"  }
            ];
            return que;
        }
        case 'name': {
            que.message = { error: `Oops! The name seems to be too lengthy.` };
            que.maxRange = 100;
            return que;
        }
        case 'phone': {
            que.message = { error: 'Good one! Dont try and trick me with an invalid number.' };
            if (botType?.toLowerCase() !== 'website') {
                que = {
                    ...que,
                    validation: true,
                    required: true
                }
            }
            return que;
        }
        case 'number': {
            que.message = { error: 'Good one! Dont try and trick me with an invalid number.' };
            if (botType?.toLowerCase() !== 'website') {
                que = {
                    ...que,
                    validation: true,
                    required: true
                }
            }
            return que;
        }
        case 'email': {
            que.message = { error: 'Err! The email is not valid.' };
            if (botType?.toLowerCase() !== 'website') {
                que = {
                    ...que,
                    validation: true,
                    required: true
                }
            }
            return que;
        }
        case 'appointment': {
            que.appointment = {
                availableDays: ['mon', 'tue', 'wed', 'thu', 'fri'],
                startTime: '09:00 AM',
                endTime: '07:00 PM',
                interval: 15
            };
            que.message = { success: 'Your appointment has been booked', unavailable: 'Slot is unavailable!' };
            return que;
        }
        case 'range': {
            que.rangePrefix = 'from';
            que.rangeSuffix = 'to';
            que.rangeStep = 5;
            que.minRange = 1;
            que.maxRange = 100;
            return que;
        }
        case 'video': {
            que.source = '';
            return que;
        }
        case 'image': {
            que.source = '';
            return que;
        }
        case 'document': {
            que.source = '';
            return que;
        }
        case 'contact': {
            que.links = [{
                title: 'Open link',
                type: 'link',
                id: uuid(),
                value: 'https://example.com',
                openInNewTab: true
            }];
            return que;
        }
        case 'rating': {
            que.options = [
                { id: uuid(), type: 'rating', value: 'Terrible' },
                { id: uuid(), type: 'rating', value: 'Bad' },
                { id: uuid(), type: 'rating', value: 'OK OK' },
                { id: uuid(), type: 'rating', value: 'Good' },
                { id: uuid(), type: 'rating', value: 'Awesome' }
            ];
            return que;
        }
        case 'question': {
            botType?.toLowerCase() !== 'website' ? que.required = true : que.required = false;
            return que;
        }
        default: {
            return que;
        }
    }

}