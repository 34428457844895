import React, { useState } from 'react';
import './single-choice.css';
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { useDispatch } from 'react-redux';
import { TextField } from "@mui/material";
import { UPDATE_ACTIVE_QUESTION, UPDATE_TOAST } from '../../../../redux/actions/actions';
import Plus from "../../../../assets/images/plus.svg";
import Minus from "../../../../assets/images/remove_circle_black.svg";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Tooltip } from '@mui/material';

export default function SingleChoice(props) {
  const methods = useFormContext();
  const botType = JSON.parse(JSON.stringify(localStorage.getItem("botType")))
  const currentQuestion = useSelector(state => state.activeQuestion);
  const questions = useSelector(state => state.flow.questions.filter(item => item.id !== currentQuestion.id));
  const dispatch = useDispatch();
  const [nextQuestion, setNextQuestion] = useState();

  const addOption = () => {
    const botType = localStorage.getItem('botType');
    if (botType === 'whatsapp' && currentQuestion.options.length >= 10) {
      return dispatch({
        type: UPDATE_TOAST,
        payload: { show: true, type: 'error', description: 'Whatsapp only allows max 10 options.' }
      });

    }
    const index = (methods.getValues().options || []).length;
    let option = {
      id: uuid(),
      type: 'button',
      value: `Option ${index + 1}`,
      next: {
        type: '',
        target: ''
      }
    };
    // Updating Active Question value in the store
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: [...currentQuestion.options, { ...option }] } });
    // Updating values of the form array using react-hook form inbuilt-method
    methods.setValue('options', [...methods.getValues().options, { ...option }]);
  }

  const deleteOption = (option) => {
    let data = methods.getValues().options.filter(data => data.id !== option.id)
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: data } });
    // Updating values of links array using react-hook form inbuilt-method
    methods.setValue('options', data);
  }

  const handleNextQuestion = (selectedQuestion, currentOptionId) => {
    let data = currentQuestion.options.map(option => {
      if (option.id === currentOptionId) {
        return {
          ...option,
          next: {
            type: selectedQuestion.type,
            target: selectedQuestion.id
          }
        }
      } else {
        return option;
      }
    });
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: data } });
    methods.setValue('options', data);

  }

  const showLabel = (id) => {
    if (id === 'end') {
      return 'End Chat';
    }
    if (id) {
      return questions.filter(question => question.id === id).map(data => data.label);
    }
    return 'Please choose one...';
  }


  const showQuestionId = (id) => {
    if (id === 'end') {
      return 'End Chat';
    }
    if (id) {
      return questions.filter(question => question.id === id).map(data => data.id);
    }
    return 'Please choose one...';
  }


  const getWhatsAppLimits = (type) => {
    if (botType !== 'whatsapp') {
      return 99999;
    }

    switch (type) {
      case '20':
        return 20;
      case '1024':
        return 1024;
      default:
        return 99999;
    }
  }

  return (
    <div style={{ paddingTop: 10 }}>
      {(botType?.toLowerCase() === 'whatsapp' && (methods.getValues().options || []).length <= 3)
        ?
        <div className="custom-input-box input-group mb-4">
          <TextField
            label="Image URL"
            size="small"
            fullWidth
            className="form-control theme-input size-14"
            defaultValue={currentQuestion.source}
            {...methods.register("source")}
            placeholder="https://example.com"
            aria-label="Username" aria-describedby="basic-addon1"
            InputProps={{
              sx: {
                fontSize: 14,
              }
            }}
            InputLabelProps={{
              sx: {
                fontSize: 14,
              }
            }}
          />
        </div>
        : null
      }
      {(botType === 'whatsapp' && (methods.getValues().options || []).length > 3)
        ?
        <div className="custom-input-box input-group mb-4">
          <TextField
            label="Header"
            size="small"
            fullWidth
            className="form-control theme-input size-14"
            defaultValue={currentQuestion.header}
            inputProps={{ maxLength: 20 }}
            {...methods.register("header")}
            placeholder="Welcome "
            aria-label="Welcome" aria-describedby="basic-addon1"
            InputProps={{
              sx: {
                fontSize: 14,
              }
            }}
            InputLabelProps={{
              sx: {
                fontSize: 14,
              }
            }}
          />
        </div>
        : null
      }
      {(botType === 'whatsapp' && (methods.getValues().options || []).length <= 3)
        ?
        <div className="custom-input-box input-group mb-4">
          <TextField
            label="Footer"
            size="small"
            fullWidth
            className="form-control theme-input size-14"
            inputProps={{ maxLength: 60 }}
            defaultValue={currentQuestion.footer}
            {...methods.register("footer")}
            placeholder="Press '0' to restart and  '1' for live chat "
            aria-label="Press '0' to restart and  '1' for live chat" aria-describedby="basic-addon1"
            InputProps={{
              sx: {
                fontSize: 14,
              }
            }}
            InputLabelProps={{
              sx: {
                fontSize: 14,
              }
            }}
          />
        </div>
        : null
      }
      {botType?.toLowerCase() === 'whatsapp'
        ?
        <div className="custom-input-box input-group mb-4">
          <TextField
            label="Body"
            size="small"
            fullWidth
            className="form-control theme-input size-14"
            defaultValue={currentQuestion.label}
            inputProps={{ maxLength: getWhatsAppLimits('1024') }}
            {...methods.register("label", { required: "Message is Required" })}
            placeholder="Enter your message"
            InputProps={{
              sx: {
                fontSize: 14,
              }
            }}
            InputLabelProps={{
              sx: {
                fontSize: 14,
              }
            }}
          />
        </div>
        : null
      }

      {(botType !== 'website')
        ?
        <div className="custom-input-box input-group mb-4">
          <TextField
            label="Error message if user enters wrong input"
            size="small"
            fullWidth
            className="form-control theme-input size-14"
            defaultValue={currentQuestion.message.error}
            inputProps={{ maxLength: getWhatsAppLimits('1024') }}
            {...methods.register("message.error")}
            placeholder="Please select an option from above given choices!"
            aria-label="Please select an option from above given choices!"
            InputProps={{
              sx: {
                fontSize: 14,
              }
            }}
            InputLabelProps={{
              sx: {
                fontSize: 14,
              }
            }}
          />
        </div>
        : null
      }


      {(botType === 'whatsapp' && (methods.getValues().options || []).length > 3)
        ?
        <div className="message-container">
          <p className="description-headings">Button</p>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control theme-input size-14"
              defaultValue={currentQuestion.button}
              inputProps={{ maxLength: getWhatsAppLimits('20') }}
              {...methods.register("button")}
              placeholder="Press "
              aria-label="Press" aria-describedby="basic-addon1"
            />
          </div>
        </div>
        : null
      }
      {(currentQuestion.options.map((option, index) => {
        return (
          <React.Fragment key={index}>
            {/* For not manipulating the static options, inputs are hidden */}
            <input
              className='d-none'
              defaultValue={option.type}
              {...methods.register(`options.${index}.type`)}
            />
            {option._id ?
              <input
                className='d-none'
                defaultValue={option._id}
                {...methods.register(`options.${index}._id`)}
              />
              :
              null
            }

            <input
              className='d-none'
              defaultValue={option.id}
              {...methods.register(`options.${index}.id`)}
            />
            <input
              className='d-none'
              defaultValue={option.next.type}
              {...methods.register(`options.${index}.next.type`)}
            />
            <input
              className='d-none'
              defaultValue={option.next.target}
              {...methods.register(`options.${index}.next.target`)}
            />
            {/* End of hidden inputs */}
            <div className="single-choice-option-container">
              <div className="message-container mb-0">
                <div className="d-flex justify-content-between">
                  <div className="custom-input-box input-group mb-3">
                    <TextField
                      label="Title"
                      size="small"
                      fullWidth
                      className="form-control theme-input size-14"
                      placeholder="option"
                      defaultValue={option.value}
                      inputProps={{ maxLength: getWhatsAppLimits('20') }}
                      {...methods.register(`options.${index}.value`)}
                      InputProps={{
                        sx: {
                          fontSize: 14,
                        }
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: 14,
                        }
                      }}
                    />
                  </div>

                  {index === currentQuestion.options.length - 1
                    ?
                    currentQuestion.options.length === 1
                      ?
                      <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                        <img src={Plus} width={22} height={25} onClick={addOption} />
                      </div>
                      :
                      <div className="d-flex justify-content-between">
                        <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                          <img src={Minus} width={25} height={25} onClick={() => deleteOption(option)} />
                        </div>
                        <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                          <img src={Plus} width={22} height={25} onClick={addOption} />
                        </div>
                      </div>
                    :
                    <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                      <img src={Minus} width={25} height={25} onClick={() => deleteOption(option)} />
                    </div>
                  }
                </div>
              </div>

              <FormControl className='mt-2 custom-input-box' size="small" fullWidth>
                <InputLabel >Go to next message</InputLabel>
                <Select
                  label="Go to next message"
                  value={showQuestionId(option.next.target)}
                  sx={{ backgroundColor: "#F3F4F5" }}
                  MenuProps={{
                    sx: {
                      minHeight: "250px",
                      height: "calc(100vh - 400px)",
                      width: "calc(50% - 20%)"
                    }
                  }}
                >
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "#E4DEF5" },
                      fontSize: 13
                    }}
                    value="Please choose one..." disabled hidden>Please choose one...</MenuItem>
                  {
                    questions.map((question, index) => {
                      return <MenuItem
                        sx={{
                          "&:hover": { backgroundColor: "#E4DEF5" }
                        }}
                        key={index} value={question.id} onClick={(e) => handleNextQuestion(question, option.id)} >
                        <Tooltip title={question.label?.length > 40 ? question.label : ""} followCursor>
                          <span>
                            {question.label}
                          </span>
                        </Tooltip>
                      </MenuItem>

                    })
                  }
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "#E4DEF5" }
                    }}
                    value="End Chat" onClick={() => handleNextQuestion({ id: 'end', type: 'question' }, option.id)}>End chat</MenuItem>
                </Select>
              </FormControl>

            </div>
          </React.Fragment>)
      }))

      }

    </div>
  );
}