import React from 'react';
import './custom-edge.css';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBezierPath } from 'react-flow-renderer';
import { DELETE_EDGE, UPDATE_ACTIVE_EDGE, REMOVE_CONNECTED_NODES } from '../../../redux/actions/actions';

const foreignObjectSize = 20;

export default function CustomEdge({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data }) {
  // State Methods
  const [xPosition, setXPosition] = useState(-999999)
  const [yPosition, setYPosition] = useState(-200)

  // Redux Methods
  const dispatch = useDispatch();
  const activeEdge = useSelector(state => state.activeEdge);

  // React Flow Method
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    style: { strokeWidth: '4px' }
  });

  const showDeleteButton = (event, id) => {
    event.stopPropagation();

    // Single choice node will always be connected to it's child. Link will only be deleted when option is removed from right sidebar
    if (!data.type || data.type === 'single-choice' || data.type === 'multi_select' || data.type === 'initial node') {
      return false;
    }

    setXPosition(event.nativeEvent.offsetX - 10);
    setYPosition(event.nativeEvent.offsetY - 10)

    dispatch({ type: UPDATE_ACTIVE_EDGE, payload: id });
  };

  const hideDeleteButton = (event, id) => {
    dispatch({ type: UPDATE_ACTIVE_EDGE, payload: null });
  }

  const deleteEdge = (data) => {
    dispatch({ type: DELETE_EDGE, payload: { id } });

    dispatch({ type: REMOVE_CONNECTED_NODES, payload: { id: data.sourceId } })

    // TODO: Needed below dispatch when enabling edit mode. Now only initial render is being used
    // dispatch({ type: ADD_EDITED_EDGE, payload: { id } });
  }

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        onPointerOver={(event) => showDeleteButton(event, id)}
      />

      {
        activeEdge === id
        &&
        <foreignObject
          x={xPosition}
          y={yPosition}
          width={foreignObjectSize}
          height={foreignObjectSize}
          className="edgebutton-foreignobject"
        >
          <div
            role="button"
            className="icon-container"
            onMouseLeave={() => hideDeleteButton(id)}>
            <span className="material-icons text-danger delete-icon "
              onClick={(event) => deleteEdge(data)}            >
              delete
            </span>
          </div>
        </foreignObject>
      }
    </>
  );
}
