import './loader.css'
import React from "react";
import image from '../../../assets/images/loader.gif';
import { useSelector } from 'react-redux';

const Loader = () => {
  const loaderAction = useSelector(state => state.loader)

  return (
    loaderAction && (
      <div className="loader" >
        <div className="loader-div">
          <img src={image} alt="loader" />
        </div>
      </div >
    )
  );
}

export default Loader;