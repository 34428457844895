import './clear-flow.css';
import { useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import { useDispatch } from "react-redux";
import { updateBot } from "../../../services/bot";
import deleteEmoji from '../../../assets/images/icon-dissatisfied.png';
import { UPDATE_DIALOGUE, UPDATE_FLOW, UPDATE_LOADER, UPDATE_TOAST } from "../../../redux/actions/actions";

export default function ClearFlow(props) {
    const openDialogue = useSelector(state => state.dialogues.clearFlowDialogue)
    const dispatch = useDispatch();
    const flow = useSelector(state => state.flow);


    const handleDelete = () => {
        const finalPayload = { ...flow, questions: [] };
        dispatch({ type: UPDATE_LOADER, payload: true });

        updateBot(finalPayload)
            .then((response) => {
                setTimeout(() => {
                    dispatch({ type: UPDATE_LOADER, payload: false });
                    dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
                    dispatch({
                        type: UPDATE_TOAST,
                        payload: { show: true, type: 'success', description: 'Flow Cleared Successfully' }
                    });
                }, 1000)
                dispatch({ type: UPDATE_DIALOGUE, payload: { clearFlowDialogue: false } });
            })
            .catch(error => {
                dispatch({ type: UPDATE_LOADER, payload: false });
                dispatch({
                    type: UPDATE_TOAST,
                    payload: { show: true, type: 'error', description: error.message || 'Could not clear flow, Please try again later...' }
                });
                dispatch({ type: UPDATE_DIALOGUE, payload: { clearFlowDialogue: false } });
            })

    };

    const handleClose = () => {
        dispatch({ type: UPDATE_DIALOGUE, payload: { clearFlowDialogue: false } });
    };

    return (

        openDialogue && (
            <>
                <div>
                    <Dialog
                        open={openDialogue}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <div className="delete-node-container">

                            <div className="delete-node-cross-icon" onClick={handleClose}>
                                <span className="material-icons-outlined text-white"
                                    style={{
                                        background: '#000',
                                        borderRadius: '50%',
                                        padding: '10px',
                                        fontSize: '18px'
                                    }}>
                                    close
                                </span>
                            </div>
                            <div className="d-flex flex-column delete-node-child">
                                <div className="delete-node-img-container">
                                    <img src={deleteEmoji} className="delete-node-img" alt='img'></img>
                                </div>

                                <div>
                                    <p className="delete-node-title">Clear Flow</p>
                                </div>

                                <div className="delete-node-content">
                                    <p style={{ color: "#999999" }}>Are you sure you want to clear all the questions from the bot flow?</p>
                                </div>

                                {/* Buttons */}
                                <div className="delete-node-buttons">
                                    <button onClick={handleClose} className="btn theme-button"
                                        style={{ marginRight: "15px", backgroundColor: "#DFE1E4" }}>
                                        Cancel</button>
                                    <button onClick={handleDelete} className="btn btn-primary theme-button" style={{ marginRight: "15px" }}>Yes, Clear!</button>

                                </div>

                            </div>
                        </div>

                    </Dialog>
                </div >
            </>
        )

    )


}