import React from "react";
import { useFormContext } from "react-hook-form";

export default function RatingComponent(props) {
  const iconNames = ['sentiment_very_dissatisfied', 'sentiment_dissatisfied', 'sentiment_neutral', 'sentiment_satisfied_alt', 'emoji_emotions'];
  const methods = useFormContext();

  return (
    <div style={{ paddingTop:10 }}>
      {(props.data.options.map((option, index) => {
        return (
          <React.Fragment key={index}>
            {/* For not manipulating the static options, inputs are hidden */}
            <input
              className='d-none'
              defaultValue={option.type}
              {...methods.register(`options.${index}.type`)}
            />
            <input
              className='d-none'
              defaultValue={option._id}
              {...methods.register(`options.${index}._id`)}
            />
            <input
              className='d-none'
              defaultValue={option.id}
              {...methods.register(`options.${index}.id`)}
            />
            {/* end of hidden inputs */}
            <div className="form-control d-flex mb-3 justify-content-between" style={{
              background: "#F3F4F5",
              color: '#797979',
              padding: '0px'
            }}>
              <input type="text" className="form-control border-0 theme-input size-14" 
                defaultValue={option.value}
                {...methods.register(`options.${index}.value`)}
                placeholder="option"
                aria-label="Username"
                aria-describedby="basic-addon1" />
              <span className="material-icons-outlined m-auto p-2">
                {iconNames[index]}
              </span>
            </div>
          </React.Fragment>
        )
      }))}
    </div>
  );
}