import { useSelector } from "react-redux";
import EditComponent from "../../../tree-ui/EditComponent";
import "./index.scss";

export default function QuestionCustomization({tab}) {
  const activeQuestion = useSelector(state => state.activeQuestion);
  
  return (
    < >
      {/* <div className="title-v1">
        Customize Bot Messages
      </div> */}

      <div style={{  paddingRight: '8px' }}>
        {!!activeQuestion.label && <EditComponent tab={tab} view="old" data={activeQuestion} />}

        {/* {
          !activeQuestion.label  &&
          <>
          {!activeQuestion.label}
            <img src={selectImage} className="select-img" />
          </>
        } */}
      </div>
    </>

  );

}
