import { useReactFlow } from "react-flow-renderer";
import { Tooltip } from '@mui/material';

export default function Slider() {
  const reactFlowInstance = useReactFlow();

  const zoom = (zoomValue) => {
    reactFlowInstance.zoomTo(zoomValue / 100)
  };

  return (
    <>
      <Tooltip title="Zoom In/Out" arrow>
        <div>
          <input type="range" min="25" max="125" defaultValue="75" className="slider" id="myRange" onChange={(e) => zoom(e.target.value)} />
        </div>
      </Tooltip>
    </>
  )
}