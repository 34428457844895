import React, { useEffect, useState } from 'react';
import './link.css';
import { Controller, useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { useDispatch } from 'react-redux';
import { UPDATE_ACTIVE_QUESTION } from '../../../../redux/actions/actions';
import { useSelector } from "react-redux";
import { TextField } from '@mui/material';
import Plus from "../../../../assets/images/plus.svg";
import Minus from "../../../../assets/images/remove_circle_black.svg";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function LinkComponent(props) {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const botType = JSON.parse(JSON.stringify(localStorage.getItem('botType')));
  const [errors, setErrors] = useState({});

  const linkData = useSelector(state => state.activeQuestion.links);
  const linkTypes = [
    { name: 'WhatsApp', value: 'whatsapp' },
    { name: 'Link', value: 'link' },
    { name: 'Twitter', value: 'twitter' },
    { name: 'LinkedIn', value: 'linkedin' },
    { name: 'SnapChat', value: 'snapchat' },
    { name: 'Telegram', value: 'telegram' },
    { name: 'Email', value: 'email' },
    { name: 'Github', value: 'github' },
    { name: 'Messenger', value: 'messenger' },
    { name: 'Skype', value: 'skype' },
    { name: 'Phone', value: 'phone' },
    { name: 'Youtube', value: 'youtube' },
    { name: 'Facebook', value: 'facebook' },
    { name: 'Instagram', value: 'instagram' },
  ];

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (name.includes("value")) {
        const index = Number(name.slice(6, 7));
        if (value.links && value.links[index]?.type === "link") {
          const regex = /^https:\/\//;
          const url = value.links[index].value;
          const match = regex.test(url);
          if (!match) {
            methods.setError(name, { type: 'custom', message: 'Value must bt start with https://' });
            setErrors({
              [name]: {
                type: 'custom', message: `"Link" should contain https://`
              }
            });
          } else {
            const payload = errors;
            delete errors[name];
            setErrors(payload);
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  const addLink = () => {
    let link = {
      id: uuid(),
      type: 'link',
      title: 'Open link',
      value: 'https://example.com',
      openInNewTab: true
    };
    // Updating Active Question value in the store
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { links: [...linkData, { ...link }] } });
    // Updating values of the form array using react-hook form inbuilt-method
    methods.setValue('links', [...linkData, { ...link }]);
    // methods.setValue('links', [...methods.getValues().links, { ...link }]);

  }

  const deleteLink = (link) => {
    let data = methods.getValues().links.filter(data => data.id !== link.id)
    console.log(data);
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { links: data } });
    // Updating values of links array using react-hook form inbuilt-method
    methods.setValue('links', data);
  }

  const changeLinkType = (socialLink, link) => {
    const updatedLinks = methods.getValues().links.map(data => {
      if (data.id === link.id) {
        return {
          ...link,
          type: socialLink.value,
        }
      }
      return data;
    });
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { links: updatedLinks } });
    methods.setValue('links', updatedLinks);

    setErrors({});
  }

  useEffect(() => {
  }, [])

  const getWhatsAppLimits = (type) => {
    if (botType !== 'whatsapp') {
      return 99999;
    }

    switch (type) {
      case '20':
        return 20;
      case '1024':
        return 1024;
      default:
        return 99999;
    }
  }

  return (
    <div style={{ paddingTop: 10 }}>
      {(linkData.map((link, index) => {
        return (
          <React.Fragment key={index}>
            <div className="link-option-container">
              <div className="message-container">
                {/* <p className="description-headings">Link*</p> */}
                <div className="d-flex justify-content-between">

                  {/* <div className="dropdown" style={{ width: "87%" }}>
                    <div data-bs-toggle="dropdown" aria-expanded="false" className="d-flex justify-content-between"
                      style={{
                        background: "#DFE1E4",
                        padding: "10px",
                        borderRadius: "5px"
                      }}>
                      <input type="text" className="mx-0 my-auto size-14"
                        style={{
                          "background": "transparent",
                          "border": "none",
                          "outline": "none",
                          "color": "#494949"
                        }}
                        defaultValue={link.type}
                        readOnly
                        {...methods.register(`links.${index}.type`, { required: "Type is Required" })}
                      />
                      <span className="material-icons-outlined" style={{ color: "#A2A3A5" }}>
                        arrow_drop_down
                      </span>
                    </div>
                    <ul className="dropdown-menu w-100 p-0" aria-labelledby="dropdownMenuButton1">
                      {
                        (linkTypes.map((socialLink, index) => {
                          return (
                            <React.Fragment key={index}>
                              <li onClick={() => changeLinkType(socialLink, link)}>
                                <h6 className="dropdown-item font-weight-bold p-2 m-0 questions-dropdown-list size-14">{socialLink.name}</h6>
                              </li>
                            </React.Fragment>
                          )
                        }))
                      }
                    </ul>
                  </div> */}

                  <FormControl className="custom-input-box" size="small" fullWidth>
                    <InputLabel >Link*</InputLabel>
                    <Select
                      label="Link*"
                      value={link.type}
                      {...methods.register(`links.${index}.type`, link.type)}
                      sx={{ backgroundColor: "#F3F4F5" }}
                      MenuProps={{
                        sx: {
                          minHeight: "250px",
                          height: "calc(100vh - 400px)"
                        }
                      }}
                    >
                      {
                        linkTypes.map((socialLink, index) => {
                          return <MenuItem
                            sx={{
                              "&:hover": { backgroundColor: "#E4DEF5" },
                              fontSize: 13
                            }}
                            key={index} value={socialLink.value} onClick={() => changeLinkType(socialLink, link)} >{socialLink.name}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  {/* <div className="link-icon" style={{ width: "10%", minWidth: "45px", marginLeft: "5px" }} role="button"> */}
                  {index === linkData.length - 1
                    ?
                    linkData.length === 1
                      ?
                      <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                        <img src={Plus} width={22} height={25} onClick={addLink} />
                      </div>
                      :
                      <div className="d-flex justify-content-between">
                        <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                          <img src={Minus} width={25} height={25} onClick={() => deleteLink(link)} />
                        </div>
                        <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                          <img src={Plus} width={22} height={25} onClick={addLink} />
                        </div>
                      </div>
                    :
                    <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                      <img src={Minus} width={25} height={25} onClick={() => deleteLink(link)} />
                    </div>
                  }

                  {/* </div> */}
                </div>

              </div>

              <div className="custom-input-box input-group mb-3">
                <TextField
                  label="Title*"
                  size="small"
                  fullWidth
                  className="form-control size-14 theme-input"
                  defaultValue={link.title}
                  placeholder="Open Link"
                  inputProps={{ maxLength: getWhatsAppLimits('20') }}
                  {...methods.register(`links.${index}.title`, { required: "Title is Required" })}
                  InputProps={{
                    sx: {
                      fontSize: 14,
                    }
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: 14,
                    }
                  }}
                />
              </div>

              <div className="custom-input-box input-group mb-3">
                <TextField
                  label="Username/ Link/ WhatsApp*"
                  size="small"
                  fullWidth
                  className="form-control size-14 theme-input"
                  {...methods.register(`links.${index}.value`, { required: "Value is Required" })}
                  placeholder="https://example.com"
                  inputProps={{ maxLength: getWhatsAppLimits('1024') }}
                  defaultValue={link.value}
                  error={ errors[`links.${index}.value`] ? true : false }
                  helperText={errors[`links.${index}.value`]?.message}
                  InputProps={{
                    sx: {
                      fontSize: 14,
                    }
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: 14,
                    }
                  }}
                  FormHelperTextProps={{
                    sx: {
                      backgroundColor:"transparent"
                    }
                  }}
                />
              </div>

              {/* For not manipulating the static options, inputs are hidden */}
              <input
                className='d-none'
                defaultValue={link.openInNewTab}
                {...methods.register(`links.${index}.openInNewTab`)}
              />
              {link._id ?
                <input
                  className='d-none'
                  defaultValue={link._id}
                  {...methods.register(`links.${index}._id`)}
                />
                :
                null
              }

              <input
                className='d-none'
                defaultValue={link.id}
                {...methods.register(`links.${index}.id`)}
              />
              {/* End of hidden inputs */}
            </div>
          </React.Fragment>
        )
      }))}

    </div>
  );
}