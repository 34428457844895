import Email from "./Email";
import Image from "./Image";
import "./edit-component.css";
import { v4 as uuid } from 'uuid';
import LinkComponent from "./Link";
import Catalogue from "./Catalogue";
import RangeComponent from "./Range";
import RatingComponent from "./Rating";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import SingleChoice from "./SingleChoice";
import DeleteNode from "../../Shared/Dialog";
import Tooltip from '@mui/material/Tooltip';
import SmartQuestion from "./SmartQuestion";
import MultipleChoice from "./MultipleChoice";
import { updateBot } from "../../../services/bot";
import AppointmentComponent from "./Appointment";
import React, { useState, useEffect, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { UPDATE_ACTIVE_QUESTION, UPDATE_FLOW, UPDATE_LOADER, UPDATE_DIALOGUE, UPDATE_TOAST } from '../../../redux/actions/actions';
import Document from "./Document/document";
import { TextField, FormControlLabel, Checkbox, Divider } from "@mui/material";
import { Controller } from "react-hook-form";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export default function EditComponent(props) {
  const { tab } = props;
  let timer = undefined;
  const methods = useForm();
  
  const flow = useSelector(state => state.flow);
  const agency = useSelector(state => state.agency);
  const [questions, setQuestionsList] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [nextQuestion, setNextQuestion] = useState({});
  const questionData = useSelector(state => state.activeQuestion);
  const { register, handleSubmit, formState: { errors }, watch } = methods;
  const botType = JSON.parse(JSON.stringify(localStorage.getItem('botType')));
  const [botId, setBotId] = useState(JSON.stringify(localStorage.getItem('botId')));
  const omitHandlerDrawer = ['button', 'multi_select'];

  const dispatch = useDispatch();

  const backSkipArray = ['email', 'phone', 'question', 'name', 'location', 'file', 'number', 'range', 'date', 'multi_select',
    'button', 'smart_question'];
  const includeInLeadsArray = ['location', 'multi_select', 'button'];

  const getErrorTitle = (type) => {
    switch (type) {
      case 'name': {
        return 'Error Message if user enters more than max allowed characters';
      }
      case 'email': {
        return 'Error message if user enters wrong email';
      }
      case 'phone': {
        return 'Error message if user enters wrong Phone Number';
      }
      default: {
        return 'Error message if user enters wrong input';
      }
    }

  }

  useEffect(() => {
    const subscription = watch(() => {
      if (timer) clearTimeout(timer);
      console.log('change deteceted');
      timer = setTimeout(() => {
        handleSubmit(onSubmit)();
      }, 1000);
    });

    return () => {
      if (timer) clearTimeout(timer);
      subscription.unsubscribe();
    }
  }, [handleSubmit, watch]);

  const onError = (errors) => {
    const error = errors[Object.keys(errors)[0]];
    dispatch({
      type: UPDATE_TOAST,
      payload: { show: true, type: 'error', description: error.message || 'Please fill all the required fields' }
    });

  };


  const onSubmit = (data) => {
    let payload = flow.questions.map(item => {
      if (item.id === questionData.id) {
        return {
          ...questionData,
          ...data
        };
      }
      return item;
    });

    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { ...questionData, ...data } })
    // dispatch({ type: UPDATE_LOADER, payload: true });
    const finalPayload = {
      ...flow,
      questions: payload
    }
    updateBot(finalPayload)
      .then((response) => {
        // dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
        dispatch({
          type: UPDATE_TOAST,
          payload: { show: true, type: 'success', description: 'Bot Updated Successfully' }
        });

        // setTimeout(() => {
        //   !omitHandlerDrawer.includes(questionData.type) && handleDrawer(false);
        // }, 500);

      })
      .catch(error => {
        // dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({
          type: UPDATE_TOAST,
          payload: { show: true, type: 'error', description: error.message || 'Could not update bot, Please try again later...' }
        });
      })
  };

  useEffect(() => {
    setQuestionsList(flow.questions.filter(que => que.id !== questionData.id));
    setFilteredQuestions(flow.questions.filter(que => que.id !== questionData.id));
    setNextQuestion(getQuestionById(questionData.next.target));
    setBotId(localStorage.getItem('botId'));

  }, [flow, questionData]);

  const handleQuestionChange = (e, question) => {
    setNextQuestion(question);
    dispatch({
      type: UPDATE_ACTIVE_QUESTION, payload: {
        next: {
          type: question.type,
          target: question.id
        }
      }
    });


    methods.setValue('next', {
      type: question.type,
      target: question.id
    });
  }

  const getQuestionById = (id) => {
    if (id === 'end') {
      return { label: 'End chat' };
    }
    if (id) {
      return (flow.questions).find(que => que.id === id);
    }

    return {};
  }


  const handleCheckBoxChange = (e) => {
    dispatch({
      type: UPDATE_ACTIVE_QUESTION, payload: {
        ...questionData,
        [e.target.name]: e.target.checked
      }
    });

    methods.setValue(e.target.name, e.target.checked);

  }

  const handleNodeDelete = (e) => {
    dispatch({ type: UPDATE_DIALOGUE, payload: { deleteNodeDialogue: true, deleteNodeData: props.data } });
  }

  const copyQuestion = () => {
    let finalQuestions = [...flow.questions, { ...questionData, _id: undefined, id: uuid(), next: { target: '', type: '' } }]
    const finalPayload = {
      ...flow,
      questions: finalQuestions
    }
    dispatch({ type: UPDATE_LOADER, payload: true });
    updateBot(finalPayload)
      .then((response) => {
        dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
        dispatch({
          type: UPDATE_TOAST,
          payload: { show: true, type: 'success', description: 'Question copied successfully' }
        });

        setTimeout(() => {
          handleDrawer(false);
        }, 500);
      })
      .catch(error => {
        dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({
          type: UPDATE_TOAST,
          payload: { show: true, type: 'error', description: error.message || 'Could not copy, Please try again later...' }
        });
      });

  }

  const routeToTeam = () => {
    window.open("https://app.botpenguin.com/" + "team", '_blank');
  }

  const handleDrawer = (status) => {
    if (props.handleModal) {
      props.handleModal(false);
    }
  };

  const getWhatsAppLimits = (type) => {
    if (botType !== 'whatsapp') {
      return 99999;
    }

    switch (type) {
      case '1024':
        return 1024;
      case 'phone':
        return 1024;
      default:
        break;
    }
  }

  return (
    <>
      <FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="edit">
            {/* Top Bar */}
            {
              props.view !== 'old' &&
              <div className="top-bar d-flex justify-content-between mb-4">
                <Tooltip title="Close">
                  <div className="icon-background" role="button" style={{ background: "#DAE0FD" }} onClick={() => handleDrawer(false)}>
                    <span className="material-icons-outlined edit-component-icon">
                      clear
                    </span>
                  </div>
                </Tooltip>

                <div className="d-flex" >
                  <Tooltip title="Copy question!">
                    <div className="icon-background me-3" style={{ background: "#DFE1E4" }} role="button" onClick={() => copyQuestion()}>
                      <span className="material-icons text-secondary edit-component-icon">
                        content_copy
                      </span>
                    </div>
                  </Tooltip>

                  <Tooltip title="Delete Question from the flow">
                    <div className="icon-background" style={{ background: "#F9D9DC" }} onClick={() => handleNodeDelete()} role="button">
                      <span className="material-icons text-danger edit-component-icon">
                        delete
                      </span>
                    </div>
                  </Tooltip>
                </div>

              </div>
            }

            {/* Title */}
            {/* {
              props.view !== 'old' && <div className="title-container">
                <p className="title-content">Customize your message</p>
              </div>
            } */}

            {(questionData.type === 'date')
              ?
              tab === "customize" &&
              <div className="custom-checkbox-container col-md-12 mb-3">
                <FormControlLabel
                  className="custom-checkbox"
                  control={
                    <Checkbox
                      id="showTime"
                      size="small"
                      checked={questionData.showTime ? true : false}
                      {...register("showTime")}
                      name="showTime"
                      onChange={handleCheckBoxChange}
                    />}
                  label="Show Time"
                />
              </div>
              : null
            }

            {/* Message */}
            <div className="message-container mt-2">
              {(questionData.type === 'button' && botType?.toLowerCase() === 'whatsapp')
                ? null
                :
                tab === "customize" &&
                <div className="custom-input-box input-group">
                  <TextField
                    label="Message"
                    size="small"
                    fullWidth
                    multiline
                    placeholder="Enter your message"
                    defaultValue={questionData.label}
                    inputProps={{ maxLength: getWhatsAppLimits('1024') }}
                    className="form-control theme-input theme-textarea"
                    {...register("label", { required: "Message is Required" })}
                    InputProps={{
                      sx: {
                        fontSize: 14,
                      }
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: 14,
                      }
                    }}
                  />
                </div>

              }


              {/* CheckBoxes */}
              <div className="row m-0 " >
                {tab === "advance" &&
                  (backSkipArray.includes(questionData.type) && botType?.toLowerCase() === 'website')
                  ? <>
                    <div className="custom-checkbox-container col-md-12 ">
                      <FormControlLabel
                        className="custom-checkbox"
                        control={
                          <Checkbox size="small"
                            checked={questionData.back ? true : false}
                            {...register("back")}
                            name="back"
                            onChange={handleCheckBoxChange}
                          />}
                        label="Allow Back"
                      />
                    </div>

                    <div className="custom-checkbox-container col-md-12 ">
                      <FormControlLabel
                        className="custom-checkbox"
                        control={
                          <Checkbox size="small"
                            checked={questionData.skip ? true : false}
                            {...register("skip")}
                            name="skip"
                            onChange={handleCheckBoxChange}
                          />}
                        label="Allow Visitor to Skip"
                      />
                    </div>

                    {
                      botType?.toLowerCase() === 'website' && ['phone', 'email', 'name'].includes(questionData.type) &&
                      <div className="custom-checkbox-container col-md-12 ">
                        <FormControlLabel
                          className="custom-checkbox"
                          control={
                            <Checkbox size="small"
                              checked={questionData.skipIfAnswered ? true : false}
                              {...register("skipIfAnswered")}
                              name="skipIfAnswered"
                              onChange={handleCheckBoxChange}
                            />}
                          label="Skip question if visitor has already answered"
                        />
                      </div>
                    }
                  </>
                  : null
                }

                {
                  tab === "advance" &&
                  <>
                    {
                      !['statement', 'image', 'contact', 'video', 'rating', 'smart_question'].includes(questionData.type) &&
                      <div className="custom-checkbox-container col-md-12">
                        <FormControlLabel
                          className="custom-checkbox"
                          control={
                            <Checkbox size="small"
                              checked={questionData.sendNotifications ? true : false}
                              {...register("sendNotifications")}
                              name="sendNotifications"
                              onChange={handleCheckBoxChange}
                            />}
                          label="Send Notification for the user reply"
                        />
                      </div>
                    }

                  </>
                }

                {
                  (includeInLeadsArray.includes(questionData.type) && botType === 'website')
                    ?
                    tab === "advance" &&
                    <div className="custom-checkbox-container col-md-12 ">
                      <FormControlLabel
                        className="custom-checkbox"
                        control={
                          <Checkbox size="small"
                            checked={questionData.includeInLeads ? true : false}
                            {...register("includeInLeads")}
                            name="includeInLeads"
                            onChange={handleCheckBoxChange}
                          />}
                        label="Include in Leads"
                      />
                    </div>
                    : null
                }
              </div>
            </div>

            {
              tab === "advance" &&
              <>

                {/* Live Chat */}
                {(questionData.type === 'live_chat' && agency.type !== 'WHITE_LABEL')
                  ? <div className="mb-3" style={{ marginTop: '-10px' }}>
                    <button className="btn btn-outline-primary px-4 py-2 mb-3" type="reset" onClick={() => routeToTeam()}>Manage Team</button>
                  </div>
                  : null
                }

                {/* Max Characters allowed in name input */}
                {(questionData.type === 'name')
                  &&
                  <>
                    <div className="custom-input-box input-group mb-3">
                      <TextField
                        label="Max Characters Allowed"
                        size="small"
                        fullWidth
                        className="form-control size-14 theme-input"
                        placeholder="Maximum characters allowed"
                        defaultValue={questionData.maxRange}
                        inputProps={{ maxLength: getWhatsAppLimits('1024') }}
                        {...register("maxRange", { required: "Error message is required" })}
                        InputProps={{
                          sx: {
                            fontSize: 14,
                          }
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          }
                        }}
                      />
                    </div>
                  </>
                }


                {/* Error Message on Wrong Input  */}
                {(questionData.type === 'phone' || questionData.type === 'email' || questionData.type === 'name' || questionData.type === 'number')
                  ?
                  <>
                    <div className="custom-input-box input-group mb-3">
                      <TextField
                        label={getErrorTitle(questionData.type)}
                        size="small"
                        fullWidth
                        defaultValue={questionData.message.error}
                        placeholder="Enter error message on wrong input"

                        className="form-control size-14 theme-input"
                        {...register("message.error")}
                        InputProps={{
                          sx: {
                            fontSize: 14,
                          }
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          }
                        }}
                      />
                    </div>
                  </>
                  : null
                }

                {(questionData.type === 'email' && botType === 'website')
                  ? <Email data={props.data} />
                  : null
                }

              </>
            }



            {
              tab === "customize" &&
              <>

                {/* Document */}
                {
                  (questionData.type === 'document')
                    ? <Document data={props.data} />
                    : null
                }

                {/* Catalogue */}
                {(questionData.type === 'catalogue' && botType?.toLowerCase() === 'whatsapp') ? <Catalogue /> : null}

                {/* Link */}
                {(questionData.type === 'contact')
                  ? <LinkComponent data={props.data} />
                  : null
                }

                {/* Image */}
                {
                  (questionData.type === 'image')
                    ? <Image data={props.data} />
                    : null
                }

                {/* Rating */}
                {
                  (questionData.type === 'rating')
                    ? <RatingComponent data={props.data} />
                    : null
                }

                {/* Single Choice */}
                {(questionData.type === 'button')
                  ? <SingleChoice data={props.data} />
                  : null
                }

                {/* Multiple Choice */}
                {(questionData.type === 'multi_select')
                  ? <MultipleChoice data={props.data} />
                  : null
                }

                {/** Video */}
                {
                  (questionData.type === 'video')
                    ?
                    <div className="custom-input-box input-group my-3">
                      <TextField
                        label="Youtube Link"
                        size="small"
                        fullWidth
                        className="form-control size-14 theme-input"
                        defaultValue={questionData.source}
                        placeholder="https://example.com"
                        {...register("source", { required: "Video url is required" })}
                        InputProps={{
                          sx: {
                            fontSize: 14,
                          }
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          }
                        }}
                      />
                    </div>

                    : null
                }

                {/* Smart Question */}
                {(questionData.type === 'smart_question')
                  ? <SmartQuestion />
                  : null
                }
              </>
            }

            {/* Appointment */}
            {(questionData.type === 'appointment')
              ? <AppointmentComponent tab={tab} data={props.data} />
              : null
            }


            {/* Range */}
            {
              tab === "customize" &&
                (questionData.type === 'range')
                ? <RangeComponent tab={tab} data={props.data} />
                : null
            }

            {
              tab === "advance" &&
              <>

                {/* Advanced Options */}
                <div className="message-container">
                  <div className="d-flex align-items-center">
                    <h6 className="delay-text" style={{ color: "#626262" }}>Add delay of </h6>
                    <div className="icon-background mx-2" style={{
                      background: "#F3F4F5",
                      border: "1px solid #d7d7d7",
                      marginRight: "10px",
                      paddingTop: "0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}>
                      <input
                        type="number"
                        defaultValue={questionData.delay}
                        onKeyUp={e => {
                          const limit = 50;
                          if (e.target.value > limit)
                            methods.setValue("delay", limit)
                        }}
                        className="edit-component-input"
                        {...register("delay")}
                      />
                    </div>
                    <h6 className="delay-text" style={{ color: "#626262" }}>seconds before this message is displayed.</h6>
                  </div>
                </div>
              </>
            }

            {
              tab === "customize" &&
              <>
                {/* Next message */}
                {(questionData.type !== 'button' && questionData.type !== 'smart_question')
                  ?
                  <FormControl id="error-message" className="custom-input-box mt-3" size="small" fullWidth>
                    <InputLabel size="small">Go to next message</InputLabel>
                    <Select
                      labelId="error-message"
                      label="Go to next message"
                      value={
                        questions.filter(ele => ele.id === nextQuestion.id).length
                          ?
                          nextQuestion.id
                          :
                          "End chat" === nextQuestion.label
                            ?
                            "End chat"
                            :
                            "null"
                      }
                      placeholder="Please choose one..."
                      sx={{
                        backgroundColor: "#F3F4F5",
                      }}
                      MenuProps={{
                        sx: {
                          minHeight: "250px",
                          height: "calc(100vh - 400px)",
                          width: "calc(50% - 20%)"
                        }
                      }}
                    >
                      <MenuItem value={null} disabled hidden>Please choose one...</MenuItem>
                      {
                        filteredQuestions.map((question, index) => {
                          return (
                            <MenuItem
                              sx={{
                                "&:hover": { backgroundColor: "#E4DEF5" },
                                fontSize:13
                              }}
                              onKeyDown={(e) => e.stopPropagation()} key={index} value={question.id} onClick={(e) => handleQuestionChange(e, question)} >
                              <Tooltip title={question.label?.length > 40 ? question.label : ""} followCursor>
                                <span>
                                  {question.label}
                                </span>
                              </Tooltip>
                            </MenuItem>
                          )

                        })
                      }
                      <MenuItem
                        sx={{
                          "&:hover": { backgroundColor: "#E4DEF5" },
                          fontSize:13
                        }}
                        value="End chat"
                        onClick={(e) => handleQuestionChange(e, { type: 'question', id: 'end', label: 'End chat' })}>End chat</MenuItem>
                    </Select>
                  </FormControl>
                  :
                  null
                }
              </>
            }

            {/* Buttons */}
            <div className="d-flex message-conatiner" style={{ marginTop: "30px" }}>
              {/* <button ref={submitButton} id="submitButton" type="submit" className="btn btn-primary theme-button" style={{ marginRight: "15px" }}>Save Changes </button> */}
              {/* <button className="btn theme-button" style={{ background: "#DFE1E4" }}><span style={{ color: "#333333" }}>Use Default</span></button> */}
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}