import { difference, filter as _filter } from 'lodash';

export const getInitialNodes = async (flowComponents, nodePositions) => new Promise((resolve, reject) => {

  const refinedComponents = refineComponents(flowComponents);
  let connectedNodes = [];
  let unConnectedNodes = [];

  let connectedComponents = [];
  let unConnectedComponents = [];

  let optionsSpace = 220;

  const { connectedComponentIds, unConnectedComponentIds } = segregateComponents(refinedComponents);

  let previousNodePosition = { x: 0, y: 0, type: 'default' };

  connectedComponents = _filter(refinedComponents, (fc) => connectedComponentIds.includes(fc.id));

  for (let [index, flowComponent] of connectedComponents.entries()) {
    const isLastNode = index === (connectedComponents.length - 1);

    const yPosition = previousNodePosition.type === 'button'
      ? previousNodePosition.y + 400
      : previousNodePosition.y + 100;


    const manualPosition = findPosition(nodePositions, flowComponent);

    switch (flowComponent.type) {

      case 'button':
        // Adding single-choice node  

        if (manualPosition) {
          connectedNodes.push(getCustomNode(flowComponent, manualPosition.x, manualPosition.y, true));
          previousNodePosition = { x: manualPosition.x, y: manualPosition.y, type: 'button' };
        } else {
          connectedNodes.push(getCustomNode(flowComponent, 50, yPosition, true));
          previousNodePosition = { x: 50, y: yPosition, type: 'button' };
        }

        // Adding choices options as node
        const optionsCount = flowComponent.options.length;
        const isOdd = optionsCount % 2 !== 0;
        const middle = Math.floor(optionsCount / 2);

        for (const [index, option] of flowComponent.options.entries()) {

          const manualOptionPosition = findPosition(nodePositions, `${option.id}-child-options-${flowComponent.id}`);

          if (manualOptionPosition) {

            connectedNodes.push(getCustomNode({ ...option, optionParentId: flowComponent.id, id: `${option.id}-child-options-${flowComponent.id}` }, manualOptionPosition.x, manualOptionPosition.y, checkIsNodeConnected(isLastNode, option)));
          } else {

            const xPosition = isOdd
              ? ((index - middle) * optionsSpace) + 50
              : index !== middle
                ? ((index - middle) * optionsSpace) + 50
                : (index * optionsSpace) + 50

            connectedNodes.push(getCustomNode({ ...option, optionParentId: flowComponent.id, id: `${option.id}-child-options-${flowComponent.id}` }, xPosition, previousNodePosition.y + 200, checkIsNodeConnected(isLastNode, option)));
          }
        }
        break;

      default:
        if (manualPosition) {
          connectedNodes.push(getCustomNode(flowComponent, manualPosition.x, manualPosition.y, checkIsNodeConnected(isLastNode, flowComponent)));
          previousNodePosition = { x: manualPosition.x, y: manualPosition.y, type: 'default' };
        } else {
          connectedNodes.push(getCustomNode(flowComponent, 50, yPosition, checkIsNodeConnected(isLastNode, flowComponent)));
          previousNodePosition = { x: 50, y: yPosition, type: 'default' };
        }
        break;
    }
  }

  unConnectedComponents = _filter(refinedComponents, (fc) => unConnectedComponentIds.includes(fc.id))

  for (let flowComponent of unConnectedComponents) {
    unConnectedNodes.push(getOpenNode(flowComponent, 50, previousNodePosition.y + 100, true));
    previousNodePosition.y += 100;
  }


  /**
   * Before resolving saving the last nodes position for later use in setting viewport
   */

  sessionStorage.removeItem('lastNodePosition');
  sessionStorage.setItem('lastNodePosition',
    connectedNodes[connectedNodes.length - 1]?.position
      ? JSON.stringify(connectedNodes[connectedNodes.length - 1]?.position)
      : null
  );
  sessionStorage.setItem('initialNodePosition',
    connectedNodes[0]?.position
      ? JSON.stringify(connectedNodes[0]?.position)
      : null
  );

  resolve({
    // connectedNodes: [getDefaultNode(!!connectedNodes.length), ...connectedNodes, ...unConnectedNodes],
    connectedNodes: [...connectedNodes, ...unConnectedNodes],
    unConnectedNodes: unConnectedNodes,
    connectedComponents: connectedComponents,
    unConnectedComponents: unConnectedComponents,
  });
});

export function getCustomNode(flowComponent, xPosition, yPosition, isConnectedToNextElement = false) {
  return {
    type: 'bpNode',
    data: { ...flowComponent, isConnected: isConnectedToNextElement },
    id: flowComponent.id,
    style: { border: "none", outline: "none", padding: 0, width: 186, height: 52 },
    position: { x: xPosition, y: yPosition },
  };
}

// function getDefaultNode(isConnected) {
//   return {
//     type: 'bpNode',
//     id: 'initial node',
//     position: { x: 50, y: 0 },
//     style: { border: "none", outline: "none", padding: 0, width: 186, height: 52 },
//     data: { label: 'Your chat flow starts from here...', type: 'initial node', id: 'initial node', isConnected },
//   };
// }

export function getOpenNode(flowComponent, xPosition, yPosition) {
  return {
    type: 'bpNode',
    data: { ...flowComponent, isConnected: false },
    id: flowComponent.id,
    style: { border: "none", outline: "none", padding: 0, width: 186, height: 52 },
    position: { x: xPosition, y: yPosition },
  };
}

export function getClass(classIsFor, componentType) {
  if (componentType.type === 'initial node') {
    return classFor('red', classIsFor);
  }

  switch (componentType.type) {
    case 'statement':
      return classFor('green', classIsFor);
    case 'question':
      return classFor('red', classIsFor);
    case 'name':
      return classFor('purple', classIsFor);
    case 'email':
      return classFor('green', classIsFor);
    case 'phone':
      return classFor('red', classIsFor);
    case 'button':
      return classFor('blue', classIsFor);
    case 'multi_select':
      return classFor('purple', classIsFor);
    case 'appointment':
      return classFor('green', classIsFor);
    case 'image':
      return classFor('red', classIsFor);
    case 'rating':
      return classFor('blue', classIsFor);
    case 'contact':
      return classFor('purple', classIsFor);
    case 'date':
      return classFor('green', classIsFor);
    case 'range':
      return classFor('red', classIsFor);
    case 'number':
      return classFor('blue', classIsFor);
    case 'location':
      return classFor('purple', classIsFor);
    case 'video':
      return classFor('green', classIsFor);
    case 'file':
      return classFor('red', classIsFor);
    case 'live_chat':
      return classFor('blue', classIsFor);
    case 'catalogue':
      return classFor('purple', classIsFor);
    case 'redirect':
      return classFor('blue', classIsFor);
    case 'smart_question':
      return classFor('green', classIsFor);
    case 'document':
      return classFor('red', classIsFor);
    case 'chatGPT':
      return classFor('blue', classIsFor);
    default:
  }
  return '';
}

export function getComponentName(componentType) {
  switch (componentType.type) {
    case 'statement':
      return 'Message';
    case 'question':
      return 'Text Question';
    case 'name':
      return 'Name';
    case 'email':
      return 'Email';
    case 'phone':
      return 'Phone Number';
    case 'button':
      if (componentType.id.includes('child-options')) {
        return 'Single Choice Option'
      }
      return 'Single Choice';
    case 'multi_select':
      return 'Multiple Choice';
    case 'appointment':
      return 'Appointment';
    case 'image':
      return 'Image';
    case 'rating':
      return 'Rating';
    case 'contact':
      return 'Web link';
    case 'date':
      return 'Date/Time';
    case 'range':
      return 'Range';
    case 'number':
      return 'Number';
    case 'location':
      return 'Location';
    case 'video':
      return 'Video';
    case 'file':
      return 'File';
    case 'live_chat':
      return 'Live Chat';
    case 'catalogue':
      return 'Catalogue';
    case 'redirect':
      return 'Redirect';
    case 'smart_question':
      return 'Smart Question';
    case 'document':
      return 'Document';
    case 'chatGPT':
      return 'Chat GPT';
    default:
  }
  return '';
}

function classFor(color, classIsFor) {
  switch (classIsFor) {
    case 'border':
      return color;

    default:
      return `${color}-background`;
  }
}

function checkIsNodeConnected(isLastNode, element) {
  if (element.next.target) {
    return true;
  }
  // if (element.next.target === 'end') {
  //   return true;
  // } else if (!isLastNode) {
  //   return true;
  // }
}

const segregateComponents = (flowComponents) => {

  let allComponents = [...flowComponents];

  const unConnectedComponentIds = [];
  const connectedComponentIds = [];

  if (!flowComponents.length) {
    return ({ unConnectedComponentIds, connectedComponentIds });
  }

  const traverseComponents = (component) => {
    connectedComponentIds.push(component.id || '');

    if (component.type === 'button') {
      component.options.forEach(option => {
        return (option.next.target && option.next.target !== 'end' && !connectedComponentIds.includes(option.next.target)) && traverseComponents(allComponents.find(c => c.id === option.next.target));
      });
    }

    return (component.next.target && component.next.target !== 'end' && !connectedComponentIds.includes(component.next.target)) && traverseComponents(allComponents.find(c => c.id === component.next.target));

  }

  traverseComponents(allComponents[0])

  unConnectedComponentIds.push(...(difference(allComponents.map(c => c.id), connectedComponentIds)));
  return ({ unConnectedComponentIds, connectedComponentIds });
}

/**
 * 
 * @param {*} flowComponents 
 * Method to add next element to components if not specified on initial render
 */
const refineComponents = (flowComponents) => {
  let components = [];
  let nodeIdsArray = ['end'];
  flowComponents.map(component =>
    nodeIdsArray.push(component.id)
  );

  for (let [index, component] of flowComponents.entries()) {
    const targetElement = !!component.next.target && component.next.target !== 'null' && nodeIdsArray.includes(component.next.target)
      ? component.next.target
      : (flowComponents[index + 1]?.id || '');

    if (component.type === 'button') {
      let options = [];

      for (let option of component.options) {
        const optionTarget = !!option.next.target && option.next.target !== 'null' && nodeIdsArray.includes(option.next.target)
          ? option.next.target
          : (flowComponents[index + 1]?.id || '');

        options.push({ ...option, next: { ...option.next, target: optionTarget } })
      }

      components.push({ ...component, next: { ...component.next, target: '' }, options });
    } else {
      components.push({ ...component, next: { ...component.next, target: targetElement } });
    }
  }

  return components;
}

const findPosition = (nodePositions, flowComponent) => {
  return nodePositions.find(p => p.nodeId === (flowComponent.id || flowComponent));
}