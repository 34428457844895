
export const getIntialEdges = async (flowComponents, unConnectedComponents) => new Promise((resolve, reject) => {

  let edges = [{
    type: 'buttonedge',
    source: 'initial node',
    id: `initial-edge`,
    target: flowComponents[0]?.id,
    data: { type: 'initial node', sourceId: 'initial node', targetId: flowComponents[0]?.id }
  }];

  flowComponents.forEach((flowComponent, componentIndex) => {

    switch (flowComponent.type) {

      case 'button':
        flowComponent.options.forEach((option, optionsIndex) => {
          edges.push({
            type: 'buttonedge',
            source: flowComponent?.id,
            id: `${option.id}-parent-child-${flowComponent.id}`,
            target: `${option.id}-child-options-${flowComponent.id}`,
            data: { type: 'single-choice', sourceId: flowComponent?.id, targetId: option.id }
          });

          let target = flowComponents[componentIndex + 1]?.id;

          if (!!option.next.target && option.next.target !== 'null' && option.next.target !== 'end') {
            target = option.next.target;
          } else if (option.next.target === 'end') {
            target = null;
          }

          edges.push({
            target,
            type: 'buttonedge',
            id: `${option.id}-child-next-action-${flowComponent.id}`,
            source: `${option.id}-child-options-${flowComponent.id}`,
            data: { type: 'single-choice-option', sourceId: option.id, targetId: target }
          });

        })
        break;

      default:

        let edgeData = {
          id: flowComponent.id, source: flowComponent.id, target: flowComponent.next.target || undefined,
          type: 'buttonedge',
          className: 'bp-edge',
          data: { type: 'normal', sourceId: flowComponent.id, targetId: flowComponent.next.target || undefined }
        }
        edges.push(edgeData);
        break;
    }

  });


  resolve(edges);
});
