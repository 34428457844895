import './notFound.css'
import image from '../../../assets/images/no-data.svg';

function NotFound() {

  return (
    <div className="no-data-found d-flex flex-column justify-content-around">
      <div className="icon">
        <img className='alert' src={image} alt=""></img>
      </div>
      <div className="title">Invalid Token</div>
      <div className="description">Please try again!</div>
    </div>
  )
}

export default NotFound;