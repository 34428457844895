import { v4 as uuid } from 'uuid';
export const createQuestion = (type, label) => {
  return {
    label,
    icon: '',
    example: '',
    required: false,
    skip: false,
    back: false,
    delay: 1,
    message: {
      error: '',
      success: '',
      unavailable: ''
    },
    validation: false,
    next: {
      type: 'question',
      target: ''
    },
    _id: '5f33e8a0dc169947560501ab',
    id: uuid(),
    type,
    name: type,
    options: [],
    links: []
  };
}

