import { LOAD_FLOW, ADD_FLOW, DELETE_FLOW, UPDATE_FLOW, RESET_FLOW } from '../actions/actions';

export const initialState = [];

const flowReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FLOW: {
      return [
        ...state,
        ...action.payload,
      ];
    }
    case UPDATE_FLOW: {
      //Only questions need to be updated
      return { ...state, questions: action.payload }
    }
    case LOAD_FLOW: {
      //Load the whole flow
      return { ...action.payload };
    }
    case DELETE_FLOW: {
      return state.filter(d => d._id !== action.payload._id);
    }
    case RESET_FLOW: {
      return [];
    }
    default: {
      return { ...state };
    }
  }
};

export { flowReducer };