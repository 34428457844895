import './toast.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { UPDATE_TOAST } from '../../../redux/actions/actions';
import info from '../../../assets/images/info_close_btn.svg';
import error from '../../../assets/images/error_close_btn.svg';
import success from '../../../assets/images/success_close_btn.svg';
import warning from '../../../assets/images/warning_close_btn.svg';
import infoImage from '../../../assets/images/toastr_info.svg';
import errorImage from '../../../assets/images/toastr_error.svg';
import successImage from '../../../assets/images/toastr_success.svg';
import warningImage from '../../../assets/images/toastr_warning.svg';
const closeImages = { info, error, success, warning };
const images = { info: infoImage, error: errorImage, success: successImage, warning: warningImage };

export default function Toast() {
  const toast = useSelector(state => state.toast);
  const dispatch = useDispatch();

  useEffect(() => {

    if (toast.show) {
      setTimeout(() => {
        dispatch({ type: UPDATE_TOAST, payload: { show: false } })
      }, 3000);
    }
  }, [toast, dispatch]);

  const closeToastr = () => {
    dispatch({ type: UPDATE_TOAST, payload: { show: false } })
  }

  return (
    <>
      {
        toast.show &&
        <div className={"toastr-container"}>
          <div className={`toastr-div ${toast.type}`}>
            <div className={"img-div"}>
              <img src={images[toast.type]} alt="logo" />
            </div>
            <div className={"content"}>
              <p className={"text-capitalize taost-title toast-p"} ></p>
              <p className='toast-p'>{toast.description}</p>
            </div>
            <div className={"close-btn-div"} onClick={closeToastr}>
              <img src={closeImages[toast.type]} alt="close" />
            </div>
          </div>
        </div >}
    </>
  )
}

