import './document.css';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { UPDATE_LOADER, UPDATE_TOAST } from '../../../../redux/actions/actions';
import { uploadImage } from '../../../../services/upload';
import { UPDATE_ACTIVE_QUESTION } from '../../../../redux/actions/actions';
import { useFormContext } from 'react-hook-form';
import DocumentIcon from "../../../../assets/images/document.svg";
import Badge from '@mui/material/Badge';
import CloseIcon from "../../../../assets/images/close.svg";

export default function Document(props) {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const [selectedDocument, setSelectedDocument] = useState(props.data.source);
  let interval = 0;

  const uploadSelectedDocument = (e) => {
    dispatch({ type: UPDATE_LOADER, payload: true })
    const formData = new FormData();
    formData.append('id', props.data.id);
    formData.append('type', 'document');
    formData.append('file', e.target.files[0], e.target.files[0].name);
    const fileName = e.target.files[0].name;

    uploadImage(formData)
      .then((response) => {
        setTimeout(() => {
          setSelectedDocument(fileName);
          methods.setValue('header', fileName);
          methods.setValue('source', response.data);

          dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { source: response.data, header: fileName } });
          dispatch({ type: UPDATE_LOADER, payload: false });
        }, 500)
      })
      .catch(error => {
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true, type: 'error', description: error.response.status === 0
              ? 'The document has exceeded the size limit of 1 MB, Please compress the file and upload again.' : error.message
          }
        });
        dispatch({ type: UPDATE_LOADER, payload: false });
        console.log(error);
      })
  }

  const showFolder = () => {
    const elem = document.getElementById('upload-input');

    elem.click();
  }

  const resetValue = () => {
    setSelectedDocument('');
    methods.setValue('source', '');
    methods.setValue('header', '');
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { source: '' } });
  }

  return (
    <>
      <div className={`d-flex mb-1`}>
        <input id="upload-input" accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf" hidden={true} type="file" className="file-replace-input" name="documents" onChange={(e) => uploadSelectedDocument(e)} />

        <div
          style={{
            border: '1px dashed rgb(17, 140, 252)',
            width: '100%',
            textAlign: 'center',
            padding: '15px',
            marginTop: '10px',
            marginBottom: '10px',
            borderRadius: '5px',
            background: '#EDEFF7',
            cursor:"pointer"
          }}
        >
          {
            selectedDocument ?
              <div className='w-100 text-center badge-border pt-2'>
                <div className='d-flex align-items-center justify-content-center flex-column'>
                  <Badge
                    sx={{ height: "100%", maxWidth: "100%" }}
                    badgeContent={<img className='cursor-pointer' width={22} height={22} src={CloseIcon} onClick={resetValue} />}
                  >
                    <img width={50} src={DocumentIcon} />
                  </Badge>
                  <div className='mt-2'>
                    {(selectedDocument.split('/').pop() || '').substr(-10)}
                  </div>
                </div>
              </div>
              :
              <div className="uploadimag">
                <div onClick={(e) => showFolder()} >
                  <div>
                    <span className="material-icons text-primary">
                      cloud_upload
                    </span>
                  </div>
                  <div>
                    <p><span className="text-primary">Click here </span> to Upload</p>
                  </div>
                </div>
              </div>
          }
        </div>

      </div>
    </>
  );
}