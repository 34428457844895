
import { useSelector } from "react-redux";
import React from 'react';
import { useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { UPDATE_ACTIVE_QUESTION } from '../../../../redux/actions/actions';
import { useDispatch } from 'react-redux';
import './smart-question.css';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Plus from "../../../../assets/images/plus.svg";
import Minus from "../../../../assets/images/remove_circle_black.svg";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Tooltip } from "@mui/material";


export default function SmartQuestion(props) {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const questions = useSelector(state => state.flow.questions.filter(item => item.id !== state.activeQuestion.id));
  const currentQuestion = useSelector(state => state.activeQuestion);


  /**
   * In order to set default values for options, otheriwse it returns undefined
   */
  // methods.setValue('options', currentQuestion.options);

  const handleNextQuestion = (selectedQuestion, currentOptionId) => {
    let data = (currentQuestion.options || []).map(option => {
      if (option.id === currentOptionId) {
        return {
          ...option,
          next: {
            type: selectedQuestion.type,
            target: selectedQuestion.id
          }
        }
      } else {
        return option;
      }
    });
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: data } });
    methods.setValue('options', data);

  }

  const handleDropdownChange = (e, currentOption, keywordType) => {
    const updatedOptions = (currentQuestion.options || []).map(data => {
      if (data.id === currentOption.id) {
        return {
          ...data,
          keywordType,
        }
      }
      return data;
    });
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: updatedOptions } });
    methods.setValue('options', updatedOptions);


  }

  const showLabel = (id) => {
    if (id === 'end') {
      return 'End Chat';
    }
    if (id) {
      return questions.filter(question => question.id === id).map(data => data.label);
    }
    return 'Please choose one...';
  }


  const addKeywords = () => {
    let keyword = {
      type: "smart_question",
      smartKeywords: [],
      keywordType: "CONTAIN",
      value: "option 1",
      id: uuid(),
      next: {
        type: "",
        target: ""
      },
      _id: undefined,
    };
    // Updating Active Question value in the store
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: [...(currentQuestion.options || []), { ...keyword }] } });
    // Updating values of the form array using react-hook form inbuilt-method
    methods.setValue('options', [...(currentQuestion.options || []), { ...keyword }]);
  }

  const deleteKeywords = (option) => {
    let data = methods.getValues().options.filter(data => data.id !== option.id)
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: data } });
    // Updating values of links array using react-hook form inbuilt-method
    methods.setValue('options', data);
  }

  const addSelectedKeyword = (e, option) => {

    if (e.key === 'Enter' && e.target.value.length) {
      e.preventDefault();
      const keyword = e.target.value.trim().split(" ").join("");

      //TODO add toast here
      if ((option.smartKeywords || []).includes(keyword)) {
        console.log('Keyword already exists');
        return;
      }

      const updatedOptions = (currentQuestion.options || []).map(data => {
        if (data.id === option.id) {
          return {
            ...data,
            smartKeywords: [...(data.smartKeywords || []), keyword],
          }
        }
        return data;
      });
      dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: updatedOptions } });
      methods.setValue('options', updatedOptions);
      e.target.value = '';
    }
  }

  const handleChipDelete = (currentKeyword, currentOption) => {
    const smartKeywords = (currentOption.smartKeywords || []).filter(keyword => keyword !== currentKeyword);
    const updatedOptions = (currentQuestion.options || []).map(option => {
      if (option.id === currentOption.id) {
        return { ...option, smartKeywords }
      }
      return option;
    })
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: updatedOptions } });
    methods.setValue('options', updatedOptions);
  };

  return (
    <>
      {(currentQuestion.options || []).map((option, index) => {
        return (
          <React.Fragment key={index}>
            {/* For not manipulating the static options, inputs are hidden */}
            <input
              className='d-none'
              defaultValue={option.id}
              {...methods.register(`options.${index}.id`)}
            />
            {option._id &&
              <input
                className='d-none'
                defaultValue={option._id}
                {...methods.register(`options.${index}._id)`)}
              />
            }
            <input
              className='d-none'
              defaultValue={option.type}
              {...methods.register(`options.${index}.type`)}
            />
            {/* End of hidden inputs */}
            <div className="smart-option-container">
              {/* <p className="description-headings">Smart Keywords</p> */}

              <div className="d-flex justify-content-between w-100">
                {/* <div className="input-group mb-3" style={{ width: "80%" }}>
                  <input type="text" className="form-control size-14 theme-input"
                    onKeyPress={(e) => addSelectedKeyword(e, option)}
                    placeholder="Type keywords and hit Enter to add" aria-label="Username" aria-describedby="basic-addon1" />
                </div> */}

                <TextField
                  label="Smart Keywords"
                  size="small"
                  fullWidth
                  className="form-control theme-input size-14"
                  placeholder="Type keywords and hit Enter to add"
                  onKeyPress={(e) => addSelectedKeyword(e, option)}
                  InputProps={{
                    sx: {
                      fontSize: 14,
                    }
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: 14,
                    }
                  }}
                />

                {/* <div className="option-icon" role="button" style={{ minWidth: "45px", marginLeft: "5px" }}> */}
                {index === currentQuestion.options.length - 1
                  ?

                  currentQuestion.options.length === 1
                    ?
                    <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                      <img src={Plus} width={22} height={25} onClick={addKeywords} />
                    </div>
                    :
                    <div className="d-flex justify-content-between">
                      <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                        <img src={Minus} width={25} height={25} onClick={() => deleteKeywords(option)} />
                      </div>
                      <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                        <img src={Plus} width={22} height={25} onClick={addKeywords} />
                      </div>
                    </div>
                  :
                  <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                    <img src={Minus} width={25} height={25} onClick={() => deleteKeywords(option)} />
                  </div>
                }
                {/* </div> */}
              </div>

              <div className="message-container">

                <Stack direction="row" spacing={1} style={{ flexWrap: 'wrap' }}>
                  {((option.smartKeywords || []).map((keyword, index) => {
                    return (
                      <React.Fragment key={index}>

                        <Chip
                          label={keyword}
                          onDelete={() => handleChipDelete(keyword, option)}
                          color="primary"
                          style={{ marginBottom: '10px', marginRight: '10px', marginLeft: '0px' }}
                          defaultValue={keyword}
                        // {...methods.register(`option.smartKeywords.${index}`)}
                        />

                      </React.Fragment>
                    )

                  }))
                  }
                </Stack>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <FormControl className='mt-2 custom-input-box' size="small" fullWidth>
                    <InputLabel >Type</InputLabel>
                    <Select
                      label="Type"
                      value={option.keywordType}
                      sx={{ backgroundColor: "#F3F4F5" }}
                      MenuProps={{
                        sx: {
                          minHeight: "250px",
                          height: "calc(100vh - 400px)"
                        }
                      }}
                    >
                      <MenuItem
                        sx={{
                          "&:hover": { backgroundColor: "#E4DEF5" },
                          fontSize: 13
                        }}
                        value="CONTAIN" onClick={(e) => handleDropdownChange(e, option, 'CONTAIN')}>Contain</MenuItem>
                      <MenuItem
                        sx={{
                          "&:hover": { backgroundColor: "#E4DEF5" },
                          fontSize: 13
                        }}
                        value="EXACT" onClick={(e) => handleDropdownChange(e, option, 'EXACT')}>Exact</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="col-md-8">
                  <FormControl className='mt-2 custom-input-box' size="small" fullWidth>
                    <InputLabel >Go to next message</InputLabel>
                    <Select
                      label="Go to next message"
                      value={showLabel(option?.next?.target || '')}
                      sx={{ backgroundColor: "#F3F4F5" }}
                      MenuProps={{
                        sx: {
                          minHeight: "250px",
                          height: "calc(100vh - 400px)",
                          width: "calc(50% - 20%)"
                        }
                      }}
                    >
                      <MenuItem value="Please choose one..." disabled hidden>Please choose one...</MenuItem>
                      {
                        questions.map((question, index) => {
                          return <MenuItem
                            sx={{
                              "&:hover": { backgroundColor: "#E4DEF5" },
                              fontSize: 13
                            }}
                            key={index} value={question.label} onClick={(e) => handleNextQuestion(question, option.id)} >
                            <Tooltip title={question.label?.length > 40 ? question.label : ""} followCursor>
                              <span>
                                {question.label}
                              </span>
                            </Tooltip>
                          </MenuItem>

                        })
                      }
                      <MenuItem
                        sx={{
                          "&:hover": { backgroundColor: "#E4DEF5" },
                          fontSize: 13
                        }}
                        value="End Chat" onClick={() => handleNextQuestion({ id: 'end', type: 'question' }, option.id)}>End chat</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

            </div>
          </React.Fragment>
        );
      })}

    </>)
}