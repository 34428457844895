import axios from 'axios';
axios.defaults.responseType = 'json';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers = {
  'Content-Type': 'application/json',
};

const httpRequest = async (
  method,
  url,
  data
) =>
  new Promise((resolve, reject) => {
    axios({
      method,
      url,
      data,
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("currentUserToken") || ""}`,
        "Content-Type": "application/json",
      }
    })
      .then(response => {
        if (String(response.status).match(/20/gi)) {
          resolve(response.data);
        } else {
          reject("Something went wrong");
        }
      })
      .catch((err) => {
        reject(err);
      })
  });


export { httpRequest };
