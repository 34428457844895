import ComponentV1 from "../ComponentsV1";
import FlowQuestions from "../Flow";
import Toast from '../../Shared/Toast';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import QuestionCustomizationLayout from "../QuestionCustomizationLayout";


import {useState} from "react";
import { updateBot } from "../../../services/bot";
import { useDispatch, useSelector } from "react-redux";


export default function OldLayout() {
  const flow = useSelector(state => state.flow);
  const [questions, setQuestions] = useState([]);
  const dispatch = useDispatch();


  return (
    <>
      <Toast />
      <div style={{ display: "flex", flexDirection: "row", height:"92vh" }}>
        <div className="h-100" style={{ width: "20%", position: "relative" }}><ComponentV1 /></div>

          <div className="h-100" style={{ width: "45%", position: "relative" }}><FlowQuestions /></div>

        <div className="h-100" style={{ width: "35%", position: "relative" }}><QuestionCustomizationLayout /></div>
      </div>
    </>
  );

}