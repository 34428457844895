import { httpRequest } from "./network";


export const updateBot = (data) => new Promise((resolve, reject) => {
  const botType = localStorage.getItem('botType');
  const url = getFlowUrl(botType);
  const payload = getFlowPayload(botType, { ...data, _id: undefined, createdAt: undefined });
  httpRequest('PUT', url, payload)
    .then(({ data }) => {
      resolve(data);
    })
    .catch(error => {
      reject(error);
    });
});

const getFlowUrl = (botType) => {
  const botId = localStorage.getItem('botId');
  let flowId;
  localStorage.getItem("flowId", flowId)
    ? flowId = localStorage.getItem("flowId", flowId)
    : flowId = '';
  switch (botType) {
    case 'website':
      return `bots/${botId}`;
    case 'whatsapp':
      return `whatsapp-automation/flows/flow/${flowId}`;
    case 'facebook':
      return `facebook-automation/flows/flow/${flowId}`;
    case 'telegram':
      return `telegram-automation/flows/flow/${flowId}`;
    case 'instagram':
      return `instagram-automation/flows/flow/${flowId}`;
    default:
      return `bots/flow/${flowId}`;
  }
}

const getFlowPayload = (botType, data) => {
  let flowId;
  localStorage.getItem("flowId", flowId)
    ? flowId = localStorage.getItem("flowId", flowId)
    : flowId = '';
  switch (botType) {
    case 'website':
      return {
        flows: [
          data
        ]
      };
    case 'whatsapp':
      return data;
    case 'facebook':
      return data;
    case 'telegram':
      return data;
    case 'instagram':
      return data;
    default:
      return data;
  }
}