import { combineReducers } from 'redux';
import { edgeReducer } from './edgeReducer';
import { flowReducer } from './flowReducer';
import { nodeReducer } from './nodeReducer';
import { loaderReducer } from './loaderReducer';
import { activeEdgeReducer } from './activeEdgeReducer';
import { editedEdgesReducer } from './editedEdgesReducer';
import { dialogueReducer } from './dialogueReducer';
import { activeQuestionReducer } from './activeQuestionReducer';
import { newQuestionReducer } from './newQuestionReducer';
import { connectedNodesReducer } from './connectedNodesReducer';
import { toastReducer } from './toastReducer';
import { nodesPositionReducer } from './nodePositionsReducer';
import { chatGPTReducer } from './chatGPTReducer';
import { agencyReducer } from './agencyReducer';

const reducer = combineReducers({
  flow: flowReducer,
  edges: edgeReducer,
  nodes: nodeReducer,
  loader: loaderReducer,
  activeEdge: activeEdgeReducer,
  editedEdges: editedEdgesReducer,
  activeQuestion: activeQuestionReducer,
  newQuestionAdded: newQuestionReducer,
  dialogues: dialogueReducer,
  connectedNodes: connectedNodesReducer,
  toast: toastReducer,
  nodePositions: nodesPositionReducer,
  isChatGPTEnabled: chatGPTReducer,
  agency: agencyReducer
});

export { reducer };
