export const ADD_FLOW = 'ADD_FLOW';
export const LOAD_FLOW = 'LOAD_FLOW';
export const UPDATE_FLOW = 'UPDATE_FLOW';
export const DELETE_FLOW = 'DELETE_FLOW';
export const RESET_FLOW = 'RESET_FLOW';

export const ADD_EDGE = 'ADD_EDGE ';
export const LOAD_EDGES = 'LOAD_EDGES ';
export const UPDATE_EDGE = 'UPDATE_EDGE';
export const DELETE_EDGE = 'DELETE_EDGE';

export const ADD_NODE = 'ADD_NODE';
export const LOAD_NODES = 'LOAD_NODES';
export const DELETE_NODE = 'DELETE_NODE';
export const UPDATE_NODE = 'UPDATE_NODE';

export const LOAD_NEW_QUESTION = 'LOAD_NEW_QUESTION';

export const LOAD_ACTIVE_QUESTION = 'LOAD_ACTIVE_QUESTION';
export const UPDATE_ACTIVE_QUESTION = 'UPDATE_ACTIVE_QUESTION';

export const UPDATE_LOADER = 'UPDATE_LOADER';

export const UPDATE_DIALOGUE = 'UPDATE_DIALOGUE';

export const UPDATE_TOAST = 'UPDATE_TOAST';

export const UPDATE_ACTIVE_EDGE = 'UPDATE_ACTIVE_EDGE';

export const ADD_EDITED_EDGE = 'ADD_EDITED_EDGE';
export const DELETE_EDITED_EDGE = 'DELETE_EDITED_EDGE';

export const ADD_CONNECTED_NODES = 'ADD_CONNECTED_NODES';
export const CLEAR_CONNECTED_NODES = 'CLEAR_CONNECTED_NODES';
export const REMOVE_CONNECTED_NODES = 'REMOVE_CONNECTED_NODES';

export const UPDATE_NODE_POSITION = 'UPDATE_NODE_POSITION';
export const GET_NODE_POSITION = 'UPDATE_NODE_POSITION';

export const UPDATE_CHAT_GPT = 'UPDATE_CHAT_GPT';
export const LOAD_AGENCY = 'LOAD_AGENCY';
