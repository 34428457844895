import './components-list.scss';
import { v4 as uuid } from 'uuid';
import React, { useState } from "react";
import search from '../../../assets/images/search.svg';

import statement from '../../../assets/images-v2/statement.png';
import name from '../../../assets/images-v2/name.png';
import phone from '../../../assets/images-v2/phone.png';
import email from '../../../assets/images-v2/email.png';
import button from '../../../assets/images-v2/button.png';
import multi_select from '../../../assets/images-v2/multi_select.png';
import question from '../../../assets/images-v2/question.png';
import file from '../../../assets/images-v2/file.png';
import appointment from '../../../assets/images-v2/appointment.png';
import location from '../../../assets/images-v2/location.png';
import date from '../../../assets/images-v2/date.png';
import image from '../../../assets/images-v2/image.png';
import contact from '../../../assets/images-v2/contact.png';
import rating from '../../../assets/images-v2/rating.png';
import range from '../../../assets/images-v2/range.png';
import number from '../../../assets/images-v2/number.png';
import video from '../../../assets/images-v2/video.png';
import smart_question from '../../../assets/images-v2/smart_question.png';
import live_chat from '../../../assets/images-v2/live_chat.png';
import chatGPT from '../../../assets/images-v2/chatGPT.png';
import redirect from '../../../assets/images/chat-components/redirect.svg';
import catalogue from '../../../assets/images-v2/catalogue.png';

import { Tooltip } from '@mui/material';
import tooltipTexts from "../../../assets/json/component-tooltip.json";

import { createQuestion } from "../../../utilities/create-question";
import { addQuestionChecks } from '../../../utilities/add-question-checks';
import { updateBot } from '../../../services/bot';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_FLOW } from '../../../redux/actions/actions';
import { UPDATE_FLOW, UPDATE_LOADER } from '../../../redux/actions/actions';

const staticTexts = {
  statement: 'Welcome to BotPenguin. I am here to help you.',
  question: 'Please type an answer',
  email: 'What is your email address?',
  name: 'Please provide your name',
  phone: 'Please provide your phone number',
  button: 'Please select one from the given options',
  multi_select: 'Please select all the options that apply',
  appointment: 'Please choose a slot for appointment',
  image: 'Here is a sample image',
  document: 'Here is a sample document',
  rating: 'How was your experience with us?',
  contact: 'Please contact us at',
  date: 'Please select a date that suits you',
  range: 'Select a range',
  number: '# Input a number',
  location: 'Please provide a location',
  video: 'Here is a video for your reference',
  file: 'Please upload your file',
  live_chat: 'Welcome to BotPenguin Live Chat',
  smart_question: 'Please type an answer',
  redirect: 'Redirect to another flow',
  catalogue: 'Go through the following link to visit our catalogue',
  chatGPT: 'Welcome to Chat GPT'
};

export default function ComponentList(props) {
  const dispatch = useDispatch();
  let [filterby, setFilter] = useState('');
  const actualflow = useSelector(state => state.flow);
  let flowQuestions = useSelector(state => state.flow.questions);
  const isChatGPTEnabled = useSelector(state => state.isChatGPTEnabled);
  const agency = useSelector(state => state.agency);


  const addComponent = (type) => {
    let text = staticTexts[type];
    if (text.toLowerCase().trim().includes('botpenguin') && agency.meta.brand) {
      text = text.replace(/botpenguin/gi, agency.meta.brand)
    }
    const botType = localStorage.getItem("botType");
    let que = createQuestion(type, text);
    que = addQuestionChecks(botType, type, que);

    const finalPayload = {
      ...actualflow,
      questions: [...flowQuestions, que]
    }
    dispatch({ type: LOAD_FLOW, payload: finalPayload });

    updateBot(finalPayload)
      .then((response) => {
        dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
      })
      .catch(error => {
        dispatch({ type: UPDATE_LOADER, payload: false });
        console.log(error);
      })
  }

  const filter = (componentName, type) => {
    const botType = localStorage.getItem('botType');
    return componentName.match(new RegExp(filterby, 'gi')) &&
      (
        (botType?.toLowerCase() === 'telegram' && ['statement', 'question', 'email', 'phone', 'button', 'image', 'contact', 'number', 'location', 'video'].includes(type)) ||
        (botType?.toLowerCase() === 'facebook' && ['statement', 'question', 'email', 'phone', 'button', 'image', 'contact', 'number', 'location', 'video'].includes(type)) ||
        (botType?.toLowerCase() === 'instagram' && ['statement', 'question', 'email', 'phone', 'button', 'image', 'contact', 'number', 'video'].includes(type)) ||
        (botType?.toLowerCase() === 'whatsapp' && ['statement', 'catalogue', 'question', 'name', 'email', 'phone', 'button', 'image', 'contact', 'number', 'location', 'video', 'document'].includes(type)) ||
        (botType?.toLowerCase() === 'website' && ['statement', 'question', 'name', 'email', 'phone', 'button', 'multi_select', 'appointment', 'image', 'rating', 'contact', 'date', 'range', 'number', 'location', 'video', 'file', 'live_chat', 'smart_question', 'chatGPT'].includes(type))
      );
  }

  const toggleFlowComponents = () => {
    props.toggleFlowComponents();
  }

  return (
    <>
      <>
        <div className="components-list nowheel" style={{ transform: props.scale ? 'scale(1.1)' : 'scale(1)' }}>
          <div>
            <div className="input-group position relative mb-1">
              <input type="text" className="form-control flow-search " style={{
                padding: "10px",
                background: "#F3F4F5",
                paddingLeft: "20px"
              }} placeholder="Search here" aria-label="search" aria-describedby="search" onChange={(e) => setFilter(e.target.value)} />
              <img src={search} className="position-absolute flow-search-box"></img>
            </div>
            <ul className="list-group b-none" style={{ borderRadius: 'none', maxHeight: props.fullLength ? 400 : '207px' }} >
              {
                filter('Message', 'statement') &&
                <Tooltip title={tooltipTexts.statement} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('statement'); toggleFlowComponents() }}>
                    <img className="flow-img" src={statement} alt="images"></img>
                    <span className="list-item-title ">Message</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Name', 'name') &&
                <Tooltip title={tooltipTexts.name} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('name'); toggleFlowComponents() }}>
                    <img className="flow-img" src={name} alt="images"></img>
                    <span className="list-item-title ">Name</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Phone Number', 'phone') &&
                <Tooltip title={tooltipTexts.phone} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('phone'); toggleFlowComponents() }}>
                    <img className="flow-img" src={phone} alt="images"></img>
                    <span className="list-item-title ">Phone Number</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Email', 'email') &&
                <Tooltip title={tooltipTexts.email} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('email'); toggleFlowComponents() }}>
                    <img className="flow-img" src={email} alt="images"></img>
                    <span className="list-item-title ">Email</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Single Choice', 'button') &&
                <Tooltip title={tooltipTexts.singleChoice} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('button'); toggleFlowComponents() }}>
                    <img className="flow-img" src={button} alt="images"></img>
                    <span className="list-item-title ">Single Choice</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Multiple Choice', 'multi_select') &&
                <Tooltip title={tooltipTexts.multipleChoice} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('multi_select'); toggleFlowComponents() }}>
                    <img className="flow-img" src={multi_select} alt="images"></img>
                    <span className="list-item-title ">Multiple Choice</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Text Question', 'question') &&
                <Tooltip title={tooltipTexts.question} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('question'); toggleFlowComponents() }}>
                    <img className="flow-img" src={question} alt="images"></img>
                    <span className="list-item-title ">Text Question</span>
                  </li>
                </Tooltip>
              }
              {
                filter('File', 'file') &&
                <Tooltip title={tooltipTexts.file} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('file'); toggleFlowComponents() }}>
                    <img className="flow-img" src={file} alt="images"></img>
                    <span className="list-item-title ">File</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Appointment', 'appointment') &&
                <Tooltip title={tooltipTexts.appointment} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('appointment'); toggleFlowComponents() }}>
                    <img className="flow-img" src={appointment} alt="images"></img>
                    <span className="list-item-title ">Appointment</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Location', 'location') &&
                <Tooltip title={tooltipTexts.location} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('location'); toggleFlowComponents() }}>
                    <img className="flow-img" src={location} alt="images"></img>
                    <span className="list-item-title ">Location</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Date/Time', 'date') &&
                <Tooltip title={tooltipTexts.dateTime} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('date'); toggleFlowComponents() }}>
                    <img className="flow-img" src={date} alt="images"></img>
                    <span className="list-item-title ">Date/Time</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Image', 'image') &&
                <Tooltip title={tooltipTexts.image} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('image'); toggleFlowComponents() }}>
                    <img className="flow-img" src={image} alt="images"></img>
                    <span className="list-item-title ">Image</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Web Link', 'contact') &&
                <Tooltip title={tooltipTexts.webLink} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('contact'); toggleFlowComponents() }}>
                    <img className="flow-img" src={contact} alt="images"></img>
                    <span className="list-item-title ">Web Link</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Rating', 'rating') &&
                <Tooltip title={tooltipTexts.rating} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('rating'); toggleFlowComponents() }}>
                    <img className="flow-img" src={rating} alt="images"></img>
                    <span className="list-item-title ">Rating</span>
                  </li>
                </Tooltip>
              }

              {
                filter('Range', 'range') &&
                <Tooltip title={tooltipTexts.range} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('range'); toggleFlowComponents() }}>
                    <img className="flow-img" src={range} alt="images"></img>
                    <span className="list-item-title ">Range</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Number', 'number') &&
                <Tooltip title={tooltipTexts.number} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('number'); toggleFlowComponents() }}>
                    <img className="flow-img" src={number} alt="images"></img>
                    <span className="list-item-title ">Number</span>
                  </li>
                </Tooltip>
              }


              {
                filter('Video', 'video') &&
                <Tooltip title={tooltipTexts.video} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('video'); toggleFlowComponents() }}>
                    <img className="flow-img" src={video} alt="images"></img>
                    <span className="list-item-title ">Video</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Smart Question', 'smart_question') &&
                <Tooltip title={tooltipTexts.smartQuestion} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('smart_question'); toggleFlowComponents() }}>
                    <img className="flow-img" src={smart_question} alt="images"></img>
                    <span className="list-item-title ">Smart Question</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Catalogue', 'catalogue') &&
                <Tooltip title={tooltipTexts.catalogue} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('catalogue'); toggleFlowComponents() }}>
                    <img className="flow-img" src={catalogue} alt="images"></img>
                    <span className="list-item-title ">Catalogu</span>
                  </li>
                </Tooltip>
              }
              {
                filter('Redirect', 'redirect') &&
                <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('redirect'); toggleFlowComponents() }}>
                  <img className="flow-img" src={redirect} alt="images"></img>
                  <span className="list-item-title ">Redirect</span>
                </li>
              }
              {
                filter('Document', 'document') &&
                <Tooltip title={tooltipTexts.document} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('document'); toggleFlowComponents() }}>
                    <img className="flow-img" src={file} alt="images"></img>
                    <span className="list-item-title ">Document</span>
                  </li>
                </Tooltip>
              }

              {
                filter('Live Chat', 'live_chat') &&
                <Tooltip title={tooltipTexts.liveChat} placement="right">
                  <li className="list-group-item d-flex align-items-center" onClick={() => { addComponent('live_chat'); toggleFlowComponents() }}>
                    <img className="flow-img" src={live_chat} alt="images"></img>
                    <span className="list-item-title ">Live Chat</span>
                    <span className="info-new-tree" >New</span>
                  </li>
                </Tooltip>
              }
              {/* {
                isChatGPTEnabled &&
                filter('Chat GPT', 'chatGPT') &&
                <Tooltip title={tooltipTexts.chatGPT} placement="right">
                  <li className="list-group-item d-flex align-items-center position-relative" onClick={() => { addComponent('chatGPT'); toggleFlowComponents() }}>
                    <img className="flow-img" src={chatGPT} alt="images"></img>
                    <span className="list-item-title ">Chat GPT</span>
                    <span className="info-new-tree" >{isChatGPTEnabled ? 'New' : 'Pro'}</span>
                  </li>
                </Tooltip>
              } */}
            </ul>
          </div>
        </div>
      </>
    </>
  );
}