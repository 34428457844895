import './delete-node.css';
import { useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import { useDispatch } from "react-redux";
import { updateBot } from "../../../services/bot";
import deleteEmoji from '../../../assets/images/icon-dissatisfied.png';
import { LOAD_ACTIVE_QUESTION, UPDATE_DIALOGUE, UPDATE_FLOW, UPDATE_LOADER, UPDATE_TOAST } from "../../../redux/actions/actions";

export default function DeleteNode({ handleModal }) {

  const { deleteNodeDialogue, deleteNodeData } = useSelector(state => state.dialogues)
  const dispatch = useDispatch();
  const flow = useSelector(state => state.flow);

  const handleDelete = () => {
    let question = deleteNodeData;
    let payload = flow.questions.filter(item => item.id !== question.id).map(item => {
      if (item.next.target === question.id) {
        return {
          ...item,
          next: {
            type: '',
            target: ''
          }
        };
      }
      return item;
    });

    dispatch({ type: UPDATE_LOADER, payload: true });
    const finalPayload = {
      ...flow,
      questions: payload
    }
    updateBot(finalPayload)
      .then((response) => {
        dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
        dispatch({ type: UPDATE_DIALOGUE, payload: { deleteNodeDialogue: false } });
        dispatch({ type: LOAD_ACTIVE_QUESTION, payload: response.questions ? response.questions[0] : response.flows[0].questions[0] })

        dispatch({
          type: UPDATE_TOAST,
          payload: { show: true, type: 'success', description: 'Deleted Successfully' }
        });
      })
      .catch(error => {
        dispatch({ type: UPDATE_LOADER, payload: false });
        console.log(error);
      })

  };

  const handleClose = () => {
    dispatch({ type: UPDATE_DIALOGUE, payload: { deleteNodeDialogue: false } });
  };

  return (

    deleteNodeDialogue && (
      <>
        <div>
          <Dialog
            open={deleteNodeDialogue}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <div className="delete-node-container">

              <div className="delete-node-cross-icon" onClick={handleClose}>
                <span className="material-icons-outlined text-white"
                  style={{
                    background: '#000',
                    borderRadius: '50%',
                    padding: '10px',
                    fontSize: '18px'
                  }}>
                  close
                </span>
              </div>
              <div className="d-flex flex-column delete-node-child">
                <div className="delete-node-img-container">
                  <img src={deleteEmoji} className="delete-node-img" alt='img'></img>
                </div>

                <div>
                  <p className="delete-node-title">Delete Question</p>
                </div>

                <div className="delete-node-content">
                  <p style={{ color: "#999999" }}>Are you sure you want to delete this question from the bot flow?</p>
                </div>

                {/* Buttons */}
                <div className="delete-node-buttons">
                  <button onClick={handleClose} className="btn theme-button"
                    style={{ marginRight: "15px", backgroundColor: "#DFE1E4" }}>
                    Cancel</button>
                  <button onClick={handleDelete} className="btn btn-primary theme-button" style={{ marginRight: "15px" }}>Yes, Delete!</button>

                </div>

              </div>
            </div>

          </Dialog>
        </div >
      </>
    )

  )


}