import { useEffect, useState } from "react";
import { useReactFlow } from "react-flow-renderer";

export default function EventCanvas() {
  const reactFlowInstance = useReactFlow();
  const [initialRender, setInitialRender] = useState(true);

  const handleSetViewPort = (event) => {
    try {
      const position = JSON.parse(sessionStorage.getItem('initialNodePosition'));

      if (position && initialRender) {
        setInitialRender(false);
        reactFlowInstance.setViewport({ x: position.x + 300, y: position.y, zoom: 1 }, { duration: 800 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener('setCanvasViewport', handleSetViewPort);

    return () => {
      window.removeEventListener('setCanvasViewport', handleSetViewPort);
    };
  }, [handleSetViewPort]);

}
