import './image.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_LOADER } from '../../../../redux/actions/actions';
import { uploadImage } from '../../../../services/upload';
import { UPDATE_ACTIVE_QUESTION, UPDATE_TOAST } from '../../../../redux/actions/actions';
import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import Badge from '@mui/material/Badge';
import CloseIcon from "../../../../assets/images/close.svg";
import useDebounce from '../../../../utilities/useDebounce';

export default function Image(props) {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const [showGifs, setShowGifs] = useState(false);
  const [gifsList, setGifsList] = useState([]);
  const [searchGif, setSearchGif] = useState("");
  const [selectedImage, setSelectedImage] = useState(props.data.source);
  const agency = useSelector(state => state.agency);

  let interval = 0;


  const openGifContainer = (e, action) => {
    e.preventDefault();
    setShowGifs(action);
  }

  const searchText = useDebounce(searchGif);
  useEffect(() => {
    if(!searchText.length) {
      setGifsList([]);
      return;
    }
    dispatch({ type: UPDATE_LOADER, payload: true });
    setGifsList([]);
    fetch(`https://api.giphy.com/v1/gifs/search?api_key=A7zgGwm71lsBSK5ED6cKTZoG6T0fTZnL&q=${searchText}`)
      .then((res) => res.json())
      .then((res) => {
        setGifsList(res.data);
        dispatch({ type: UPDATE_LOADER, payload: false });
      })
      .catch(error => {
        dispatch({ type: UPDATE_LOADER, payload: false });
      });

  }, [searchText])

  const searchGifs = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setGifsList([])
      if (interval) {
        clearInterval(interval);
      }
      interval = setInterval(() => {
        dispatch({ type: UPDATE_LOADER, payload: true });
        fetch(`https://api.giphy.com/v1/gifs/search?api_key=A7zgGwm71lsBSK5ED6cKTZoG6T0fTZnL&q=${event.target.value || 'hello'}`)
          .then((res) => res.json())
          .then((res) => {
            //TODO- if res.data.length =0 show toast that tehre are no matching gifs
            setGifsList(res.data);
            dispatch({ type: UPDATE_LOADER, payload: false });
            clearInterval(interval);
            interval = null;
            event.target.value = '';
          })
          .catch(error => {
            dispatch({ type: UPDATE_LOADER, payload: false });
            clearInterval(interval);
            interval = null;
          });

      }, 1000);
    }
  }

  const getGifUrl = (url) => {
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { source: url } })
    setSelectedImage(url);
    methods.setValue('source', url);

    dispatch({ type: UPDATE_LOADER, payload: true });
    setTimeout(() => {
      setShowGifs(false);
      dispatch({ type: UPDATE_LOADER, payload: false });
    }, 1000)
  }

  const uploadSelectedImage = (e) => {
    const formData = new FormData();
    formData.append('id', props.data.id);
    formData.append('type', 'image');
    formData.append('file', e.target.files[0], e.target.files[0].name);

    const uploadFile = e.target.files[0];
    if (uploadFile.size / 1024 / 1024 <= 1) {
      dispatch({ type: UPDATE_LOADER, payload: true })
      uploadImage(formData)
        .then((response) => {
          setTimeout(() => {
            setSelectedImage(response.data);
            methods.setValue('source', response.data);

            dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { source: response.data } });
            dispatch({ type: UPDATE_LOADER, payload: false });
          }, 500)
        })
        .catch(error => {
          dispatch({ type: UPDATE_LOADER, payload: false });
          console.log(error);
        })
    } else {
      dispatch({
        type: UPDATE_TOAST,
        payload: { show: true, type: 'error', description: 'Image should be less than 1MB' }
      });
    }
  }

  const removeImage = () => {
    setSelectedImage("");
    methods.setValue('source', "");
  }

  const showFolder = () => {
    const elem = document.getElementById('upload-input');

    elem.click();
  }

  return (
    <>
      {/* <div className="d-flex">
        <button className="btn btn-outline-primary me-3 theme-button" onClick={(e) => openGifContainer(e, false)}>Single Image</button>
        <button className="btn btn-outline-primary " style={{ padding: '0 30px' }} onClick={(e) => openGifContainer(e, true)}>GIF</button>
      </div> */}

      {/* GIF Container */}
      {/* {showGifs
        ?
        <> */}
      <div className="d-flex flex-column">
        <div >
          <div className="input-group ">
            <TextField
              size='small'
              type="text"
              label="GIF"
              className='mt-2'
              fullWidth
              // onKeyDown={(e) => searchGifs(e)}
              onChange={e => setSearchGif(e.target.value)}
              value={searchGif}

              InputProps={{
                sx: {
                  fontSize: 14,
                }
              }}
              InputLabelProps={{
                sx: {
                  fontSize: 14,
                }
              }}
              // {...register("label")}
              placeholder="Search keyword and hit Enter.."
            />
          </div>
        </div>

        <div className='gif-container gif-image-parent' style={{ marginTop: gifsList?.length ? 15 : "", overflowX:"hidden" }}>
          <div className="row">
            {gifsList.map((gif, index) => {
              return (
                <React.Fragment key={index}>
                  <div className='col-lg-4 col-md-6 col-sm-12 p-0' onClick={() => getGifUrl(gif.images.original.url)}>

                    <img className='gif-image' src={gif.images.original.url} role="button" />
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>

      {/* </>
        :
        <> */}
      <div
        style={{
          border: '1px dashed rgb(17, 140, 252)',
          width: '100%',
          textAlign: 'center',
          padding: '15px',
          marginBottom: '10px',
          borderRadius: '5px',
          background: '#EDEFF7'
        }}
        className="d-flex flex-column">
        {selectedImage
          ?
          <>
            <div className='d-flex justify-content-between '>
              <div
                style={{
                  width: '100%',
                  height: '200px',
                  background: 'transparent',
                  // outline: '2px solid #118cfc',
                  // borderRadius: '50%',
                  margin: 'auto'
                }}
                className='badge-border'
              >
                <Badge
                  sx={{ height: "100%", maxWidth: "100%", cursor: "pointer" }}
                  badgeContent={<img width={22} height={22} src={CloseIcon} />}
                  color="error"
                  onClick={removeImage}
                >
                  <img
                    className='primary'
                    style={{
                      maxWidth: '100%',
                      height: '100%',
                      // padding: '5px'
                      borderRadius: 5
                    }}
                    src={selectedImage === 'https://cdn.botpenguin.com/assets/assets/icons/botpenguin-avatar.png'
                      ? agency?.type === 'WHITE_LABEL'
                        ? agency.meta.logo
                        : 'https://cdn.botpenguin.com/assets/assets/icons/botpenguin-avatar.png'
                      : selectedImage} />
                </Badge>

              </div>

            </div>
            {/* 
                <div>
                  <div className='mx-0 my-auto uploadimag'>
                    <input id="upload-input" hidden={true} type="file" accept="image/png, image/jpeg, image/jpg" className="file-replace-input" name="images" onChange={(e) => uploadSelectedImage(e)} />
                    <button style={{ marginTop: '20px' }} className='btn btn-primary theme-button' onClick={e => { e.preventDefault(); showFolder(); }}>Replace</button>
                  </div>
                </div> */}
          </>
          : <>
            <div className="uploadimag">
              <input type="file" accept="image/png, image/jpeg, image/jpg" className="file-input" name="images" onChange={(e) => uploadSelectedImage(e)} />
              <div>
                <div style={{ marginTop: '-100px' }}>
                  <span className="material-icons text-primary">
                    cloud_upload
                  </span>
                </div>
                <div>
                  <p><span className="text-primary">Click here </span> to Upload</p>
                </div>
              </div>
            </div>
          </>
        }

      </div>
      {/* </>
      } */}



    </>
  );
}